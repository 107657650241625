<div class="home-navbar ">
  <Admin_sidebar />
</div>

<div class="app-content mt-1">
  <Admin_navbar />
  <div class="content">
        <!-- Main content goes here -->
      </div>
    </div>
