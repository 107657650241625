<div class="home-navbar">
  <Sidebar />
</div>

<div class="app-content">
  <Navbar />
  <div class="content">
    <div class="upload-search-row">
    </div>
    <div class="card table-card">
      <div class="card-body">
        <table id="content_table" class="table align-item table-flush-item content-table">
          <thead class="thead-light">
            <tr>
              <th class="text-uppercase text-xxs fw-bold opacity-9">First Name</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Last Name</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Product Name</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Product</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Amount</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
            </tr>
          </thead>

          <tbody>
            <template v-for="(influencer, index) in influencers.influencers" :key="influencer.influencer_id">
              <tr v-for="product in influencer.products" :key="product.product_name">
                  <td class="text-start">
                  <span class="text-xxs font-weight-light"> {{ influencer.influencer_first_name }}</span>
                  </td>
                  <td class="text-start">
                      <span class="text-xxs font-weight-light mb-0">{{ influencer.influencer_last_name }}</span>
                  </td>
                  <td class="text-start">
                      <span class="text-xxs font-weight-light mb-0">{{ product.product_name }}</span>
                  </td>
                <td class="text-start">
                  <img :src="product.image_url" alt="Product Image" width="50" height="50">
                </td>
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">{{ product.price}}</span>
                  
                </td>
                
                <td class="align-item">
                  <button 
                    class="btn-sm action-btn font-weight-light text-xxs" 
                    >
                    View
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>