/* global $ */
import Swal from 'sweetalert2';
import Product_detail from '@/views/Customer_Management/Customer_Search_Products/ProductDetailView.vue';
import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue'
import EventBus from '@/eventBus.js';

export default {
  name: 'HomeNavbarView',
  components: {
    Home_navbar,
    Influencer_navbar,
    Product_detail,
    Filter_product
  },
  data() {
    return {
      video_url: '',
      product: null,
      filteredProducts: [],
      showDropdown: false,
      quantity: 1,
      selectedSize: null,
      sizeProductId: null,
      sizes: [0],
      cart: [],
      showSizelert: false,
      search_query: "",
      allProducts: []
    };
  },
  computed: {
    filteredProducts() {
      const query = this.search_query.toLowerCase();
      return this.allProducts.filter(product => {
        return product.product_name.toLowerCase().includes(query) 
          || product.description.toLowerCase().includes(query)||
          product.product.brand__brand_name.toLowerCase().includes(query) ||
          product.product.category__category_name.toLowerCase().includes(query)
      });
    }
  },

  created() {
    // Example: Check user authentication status
    this.LoggedIn = !!localStorage.getItem('token'); // Adjust according to your logic
    this.fetchCartDetails();
    EventBus.on('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });

  },
  beforeDestroy() {
    // Cleanup event listener
    EventBus.off('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
  },

  methods: {
    update_search_query(query) {
      this.search_query = query;
    },
    filter_product_list(data) {
      this.allProducts = data;
      },
      fetchSearchProducts() {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`;
    
        fetch(FETCH_PRODUCT_URL)
          .then(response => response.json())
          .then(data => {
            this.allProducts = data;
            this.filteredProducts = data;
          })
          .catch(error => console.error('Error fetching products:', error));
      },
    
      filterProducts() {
        const query = this.search_query.toLowerCase();
        this.filteredProducts = [...this.allProducts];
    
        if (query) {
          const search = new RegExp(query, 'i');
          this.filteredProducts = this.filteredProducts.filter((product) => {
            const match = (
              search.test(product.product.product_name) ||
              search.test(product.product.brand__brand_name) ||
              search.test(product.product.description) ||
              search.test(product.product.category__category_name)
            );
            return match;
          });
        }
      },
  // Fetch the products from the API
  async fetchProducts(image_id) {
    const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_product/${image_id}`;
    try {
      const response = await fetch(FETCH_PRODUCT_URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      this.product = data;
      this.sizes = data.sizes;
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  },
  // Video loading logic
  async loadVideo() {
    const video_id = this.$route.params.video_id;
    
    if (video_id) {
        try {
            const LOAD_VIDEO_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_video/${video_id}`;

            // Fetch the video data
            const response = await fetch(LOAD_VIDEO_URL, {
                headers: {
                },
            });
            const data = await response.json();
            const video_url = data.video_url;

            $('#video_source').attr('src', video_url);
            $('#video_player')[0].load();

        } catch (error) {
            console.error('Error loading video:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error Loading Video',
                text: 'There was an error loading the video. Please try again.',
            });
        }
    } else {
        Swal.fire({
            icon: 'warning',
            title: 'Missing Video ID',
            text: 'No video ID was provided. Please select a valid video.',
        });
    }
},

    // Selecting a result (for dropdown)
    selectResult(result) {
      if (result.type === 'product') {
        this.loadVideo(result.video_id); // Load the video associated with the selected product
      }
      this.showDropdown = false;
    },

    // Increase quantity
    increaseQuantity() {
      this.quantity++;
    },

    // Decrease quantity
    decreaseQuantity() {
      if (this.quantity > 0) {
        this.quantity--;
      }
    },


    getLocalStorage(name) {
      const value = localStorage.getItem(name);
      return value ? JSON.parse(value) : null;
    },

    setLocalStorage(name, value) {
      localStorage.setItem(name, JSON.stringify(value));
    },

    async addToCart(productId) {
      if (!this.selectedSize) {
        this.showSizelert = true;
        setTimeout(() => {
          this.showSizelert = false;
        }, 2000);
        return;
      }
      const quantity = this.quantity;
      productId = this.sizeProductId;
    
      if (this.isLoggedIn()) { // Function to check if the user is logged in
        try {
          const token = localStorage.getItem('token');
          const ADD_TO_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;
          await fetch(ADD_TO_CART_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              product_id: productId,
              quantity: quantity
            })
          });
    
            EventBus.emit('cart-updated', this.cartQuantity);
            EventBus.emit('toggle-right-sidebar');
        } catch (error) {
          console.error('Error adding to cart:', error);
          
        }
      } else {
        // Handle the case for guest users
        const cart = this.getLocalStorage('cart') || [];    
        const existingProduct = cart.find(item => item.product_id === this.sizeProductId && item.size === this.selectedSize);
        
        if (existingProduct) {
          existingProduct.quantity += quantity;
        } else {
          cart.push({
            id: this.product.product_id,
            product_name: this.product.product.product_name,
            quantity: quantity,
            price: this.product.price,
            color: this.product.color,
            size: this.selectedSize,
            brand: this.product.product.brand__brand_name,
            image: this.product.product.image_url,
            product_id: this.sizeProductId,
          });
        }
        
        this.setLocalStorage('cart', cart);
        this.fetchCartDetails().then(() => {
          EventBus.emit('cart-updated', this.cartQuantity);
          EventBus.emit('toggle-right-sidebar');
        })
      }
    },
    
    async fetchCartDetails() {
      const token = localStorage.getItem('token');
  
      if (token) {
        // User is logged in, fetch the cart from the server
        const CART_API_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/get_cart_details/`;
        try {
          const response = await $.ajax({
            url: CART_API_URL,
            method: 'GET',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
    
          if (response) {
            if (response.cart_products.length > 0) {
            this.cartItems = response.cart_products;
                
            this.cartQuantity = response.total_quantity;
            this.cartTotal = parseFloat(response.total_price);
          } else {
              this.cartProducts = [];
              this.closeCart();
          }
          }
          EventBus.emit('cart-updated', this.cartQuantity);

        } catch (error) {
          console.error('Failed to fetch cart from the server:', error);
    
          const cart = this.getLocalStorage('cart') || [];
    
          this.cartItems = cart;
          this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
          this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
        }
      } else {
        const cart = this.getLocalStorage('cart') || [];
        this.cartItems = cart;
        
        this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
        this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
      }
    },
    
    // Function to check if the user is logged in
    isLoggedIn() {
      const token = localStorage.getItem('token');
      return token !== null; 
    },
    selectSizeProduct(product) {
      this.selectedSize = product.size;
      this.sizeProductId = product.product_id;
    },
    
  },

  mounted() {
    // Fetch products when component is mounted
    const image_id = this.$route.params.id; // Get image_id from route params
    if (image_id) {
      this.fetchProducts(image_id); // Pass image_id to fetchProducts
    }

    // Optionally load video if a specific product ID is present in the route
    const video_id = this.$route.params.video_id;

    if (video_id) {
      this.loadVideo(video_id);
    }
    this.fetchCartDetails();
    this.fetchSearchProducts();

  },
  watch: {
    '$route.params.id': {
      immediate: true, // Will fetch on initial load as well
      handler(newImageId) {
        if (newImageId) {
          this.fetchProducts(newImageId); // Refetch product when image_id changes
        }
      }
    },
    sizes: {
      immediate: true,
      handler(all_sizes) {
        if (all_sizes.length === 1) {
          this.selectedSize = all_sizes[0].size;
        } else {
          this.selectedSize = null;
        }
      }
    }
  },
  search_query() {
    this.filterProducts();
  }
};

