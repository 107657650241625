/* global $ */
import Swal from 'sweetalert2';
import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';
import 'datatables.net';
import 'datatables.net-bs4';



export default {
  name: 'ContentUploadView',
  components: {
    Sidebar,
    Navbar,
  },
  data() {
    return { 
      impersonating: localStorage.getItem('impersonate') === 'true',
      dataTable: null,
      videos: [] 
    };
  },
  updated() {
    // Initialize DataTable whenever videos data is updated
    this.$nextTick(() => {
      if ($.fn.DataTable.isDataTable('#content_table')) {
        $('#content_table').DataTable().destroy(); // Destroy previous instance
      }
      $('#content_table').DataTable({
        pageLength: 10,
        lengthChange: false,
        searching: true,
        ordering: true,
        paging: true,
        info: true,
        autoWidth: false,
        columnDefs: [
          { orderable: false, targets: 5 } // Disable ordering on the 'Action' column
        ]
      });
    });
  },
  methods: {
    initImagePreview() {
      const imagePreview = this.$refs.imagePreview;
      const coverImageInput = this.$refs.coverImage;
      const uploadIcon = document.getElementById('uploadIcon');
    
      coverImageInput.addEventListener('change', (e) => {
        if (e.target.files.length > 0) {
          const file = e.target.files[0];
          const reader = new FileReader();
    
          reader.onload = (e) => {
            imagePreview.src = e.target.result;
            imagePreview.classList.remove('d-none');
            uploadIcon.classList.add('d-none');
          };
    
          reader.readAsDataURL(file);
        }
      });
    },

    previewVideo(event) {
      const file = event.target.files[0];
      if (file) {
        const url = URL.createObjectURL(file);
        document.getElementById('videoPreview').src = url;
        document.getElementById('videoPreview').style.display = 'block';
      }
    },
    async fetchVideos() {
      try {
        const token = localStorage.getItem('token');
        const impersonate = localStorage.getItem('impersonate');
        const brand_id = localStorage.getItem('brand_id');

        let FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_brand_videos/`;

        if (impersonate === 'true') {
          FETCH_VIDEOS_URL += `?brand_id=${brand_id}&impersonate=true`;
        }

        const response = await fetch(FETCH_VIDEOS_URL, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        const data = await response.json();
        this.videos = data;
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },
    uploadVideo() {
      $('.submit-btn').on('click', async (e) => {
        e.preventDefault();
    
        Swal.fire({
          title: 'Uploading Video',
          text: 'Please wait while we upload your video...',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
              Swal.showLoading();
          }
        });
    
        // Create form data object
        const formData = new FormData();
        formData.append('description', $('#description').val());
        formData.append('sub_description', $('#sub_description').val());
        formData.append('content_type_id', $('#content_type_id').val());
        formData.append('video_url', $('#video_url')[0].files[0]);
        formData.append('cover_image', $('#cover_image')[0].files[0]);
    
        const token = localStorage.getItem('token');
        const UPLOAD_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/upload_video/`;
    
        try {
          // Perform the AJAX request to upload the video
          const response = await $.ajax({
            url: UPLOAD_VIDEOS_URL,
            method: 'POST',
            data: formData,
            processData: false,  // Important for FormData
            contentType: false,  // Important for FormData
            headers: {
              'Authorization': `Token ${token}`,
            }
          });
          if (response) {
            Swal.fire({
              icon: 'success',
              title: 'Upload Successful',
              text: 'Your video has been uploaded successfully!',
            }).then(() => {
              window.location.reload();  // Reload page after upload
            });
          } else {
            throw new Error('Upload failed');
          }
        } catch (error) {
          console.error('Error uploading video:', error);
          Swal.fire({
            icon: 'error',
            title: 'Upload Failed',
            text: 'Failed to upload video. Please try again.',
          });
        }
      });
    },
    
  async deleteVideo(video_id) {
    // ... rest of the deleteVideo method
  
    const token = localStorage.getItem('token');
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E39356',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Deleting Video',
          text: 'Please wait while the video is being deleted...',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        try {
          const DELETE_VIDEO_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/delete_video/${video_id}`;

          const response = await $.ajax( {
            url: DELETE_VIDEO_URL,
            method: 'DELETE',
            headers: {
              'Authorization': `Token ${token}`
            }
          });
          if (response.status === "success") {
            Swal.fire(
              'Deleted!',
              'The video has been deleted.',
              'success'
            ).then(() => {
              window.location.reload();
            });
          } else {
            throw new Error('Deletion failed');
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Deletion Failed',
            text: 'Failed to delete video. Please try again.',
          });
        }
      }
    });
  }
},
  mounted() {
    this.fetchVideos();
    this.uploadVideo(); // Initialize video upload handling
    this.initImagePreview()
    
  },
  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
  }
};



