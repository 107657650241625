<div class="home-navbar ">
    <Admin_sidebar />
</div>

<div class="app-content">
    <Admin_navbar />
    
    <div class="content">
        <div class="upload-search-row">
        </div>
        <div class="card table-card">
            <div class="card-body">
                <table id="orders_table" class="table align-item table-flush-item content-table">
                    <thead class="thead-light">
                        <tr>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Customer</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Order No</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Order Date</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Total</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Location</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Status</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="order in orders" :key="order.id">
                            
                            <td class="text-start">
                                <img :src="user.image" alt="Profile Image" width="50" height="50">
                            </td>
                            <td class="align-item text-start">
                                <div>
                                <strong>{{ order.user.first_name }} {{ order.user.last_name }}</strong>
                                <br>
                                <small class="text-xxs font-weight-light mb-0">{{ order.user.email }}</small>
                                </div>
                                </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ order.order_number }}</span>
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ order.order_date }}</span>
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ order.total_orders }}</span>
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ order.location }}</span>
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ order.order_status }}</span>
                            </td>
                            <td class="text-start">
                                <button class="btn-sm action-btn font-weight-light text-xxs">
                                    Manage
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>