import View_brands from '@/views/Brand_Management/View_Brands/ViewBrands.vue'
import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue'
import EventBus from '@/eventBus.js';

export default {
  name: 'ViewBrands',
  components: {
    View_brands,
    Home_navbar,
    Filter_product
  },
  data: () => ({ brands: [], allProducts: [], search_query: "",selectedBrand: null, brandProducts: [], showSizes: false,UserLoggedIn: false,
    selectedProduct: null,selectedSize: null,sizeProductId: null,      addedToWishlist: false,
  }),

  computed: {
    filteredProducts() {
      const query = this.search_query.toLowerCase();
      const search = new RegExp(query, 'i');
      return this.allProducts.filter(product => search.test(product.product.product_name) 
        || search.test(product.product.description) 
      );
    },

    filteredBrandProducts() {
      if (!this.selectedBrand) return [];
      return this.allProducts.filter(product => product.product.brand__brand_name === this.selectedBrand.brand_name);
    },
    filteredBrandSearch() {
      const query = this.search_query.toLowerCase();
    
      if (this.selectedBrand) {
        if (query === '') {
          return this.brandProducts;
        } else {
          return this.brandProducts.filter(product =>
            product.product.product_name.toLowerCase().includes(query)
            || product.product.description.toLowerCase().includes(query)
          );
        }
      } else {
        return this.filteredProducts;
      }
    },
  },

  mounted() {
    this.fetchProducts();
    if (this.$route.params.brandId) {
        this.fetchBrandProducts(this.$route.params.brandId);
    }
  },

  methods: {

    handleProductClick(product) {
      this.selectedProduct = product;
      this.sizes = this.selectedProduct.sizes;
      this.showSizes = !this.showSizes;
    },
    async handleAddToCart(size) {
      // Add size to product object
      this.selectedSize = size.size;
      this.sizeProductId = size.product_id;
    },

    update_search(event) {
      this.search_query = event.target.value.trim().toLowerCase();
    },
    filter_product_list(data) {
      this.allProducts = data;
      if (this.selectedBrand) {
        this.brandProducts = this.allProducts.filter(
          (product) => product.product.brand__brand_name === this.selectedBrand.brand_name
        );
      }
    },
    async fetchProducts() {
      const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`;
      try {
        const response = await fetch(FETCH_PRODUCT_URL, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
        const data = await response.json();
        this.allProducts = data;

      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
    selectBrand(brandId) {
        this.$router.push({ name: 'view-brands-products', params: { brandId } });
        this.fetchBrandProducts(brandId)
    },
    
      async fetchBrandProducts(brandId) {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_products_by_brand_api/`;
        const params = { brand_id: brandId };
    
        try {
          const response = await fetch(FETCH_PRODUCT_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
          });
          const data = await response.json();    
          this.brandProducts = data;
          this.selectedBrand = { brand_name: data[0].product.brand__brand_name };
        } catch (error) {
          console.error('Error fetching brand products:', error);
        }
      },
      handleImageError(event) {
        console.error(`Image loading error: ${event.target.src}`, event);
      },

      isLoggedIn() {
        const token = localStorage.getItem('token');
        return token !== null;
      },

      async addToCart(product) {
        this.showSizes = false;
        const quantity = 1;
        const productId = this.sizeProductId;
  
        if (this.isLoggedIn()) { // Function to check if the user is logged in
          try {
            const token = localStorage.getItem('token');
            const ADD_TO_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;
            await fetch(ADD_TO_CART_URL, {
              method: 'POST',
              headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                product_id: productId,
                quantity: quantity
              })
            });
            EventBus.emit('cart-updated', this.cartQuantity);
            if (this.isRightSidebarOpen) {
              EventBus.emit('update-cart');
            } else {
              EventBus.emit('toggle-right-sidebar');
            }
          } catch (error) {
            console.error('Error adding to cart:', error);
          }
        } else {
          // Handle the case for guest users
          const cart = this.getLocalStorage('cart') || [];
  
          const existingProduct = cart.find(item => item.id === productId);
          if (existingProduct) {
            existingProduct.quantity += quantity;
          } else {
            cart.push({
              id: product.product_id,
              product_name: product.product.product_name,
              quantity: quantity,
              price: product.price,
              color: product.color,
              size: this.selectedSize,
              brand: product.product.brand__brand_name,
              image: product.product.image_url,
              product_id: productId
            });
          }
          this.setLocalStorage('cart', cart);
          this.fetchCartDetails().then(() => {
            EventBus.emit('cart-updated', this.cartQuantity);
            if (this.isRightSidebarOpen) {
              this.isRightSidebarOpen = true;
              EventBus.emit('update-cart');
            } else {
              EventBus.emit('toggle-right-sidebar');
            }
          })
        }
      },

      async fetchCartDetails() {
        const cart = this.getLocalStorage('cart') || [];
      
        for (const item of cart) {
        
            const GET_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/fetch_image/${item.id}/`;
            const response = await fetch(GET_IMAGE_URL);
            
            if (!response.ok) {
              throw new Error('Failed to fetch image URL');
            }
            const imageData = await response.json();
            item.image = imageData;
        }
      
        this.cartItems = cart;
        this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
        this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
        EventBus.emit('cart-updated', this.cartQuantity);
      },
      
      async addToWishlist(product_id) {
        const token = localStorage.getItem('token');
        if (!token) {
          this.showLoginAlert = true;
          setTimeout(() => {
            this.showLoginAlert = false;
          }, 5000);
          return;
        }
      
        // Prepare the request data
        const formData = new FormData();
        formData.append('productdetail_ids', product_id);
      
        try {
          const ADD_TO_WISHLIST_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_wishlist/`;
          const response = await fetch(ADD_TO_WISHLIST_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Token ${token}`,
            },
            body: formData,
          });
      
          const result = await response.json();
          if (response.ok && response.status === 201) {
            // If the response is successful
            this.addedToWishlist = true;
            setTimeout(() => {
              this.addedToWishlist = false;
            }, 2000);
          } else if (response.status === 200) {
            // Handle case when the product is already in the wishlist (409 Conflict)
            this.addedToWishlist = true;
            setTimeout(() => {
              this.addedToWishlist = false;
            }, 2000);
          } else {
            // If there's an error in the API call (e.g., validation error)
            alert(result.error || 'Failed to add product to the wishlist.');
          }
        } catch (error) {
          // Handle unexpected errors
          alert('Something went wrong. Please try again.');
        }
      }
  
    }
  }