import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue'
import axios from 'axios'; // Import axios
import Swal from 'sweetalert2';
import EventBus from '@/eventBus.js';

export default {
  name: 'CategoriesView',
  components: {
    Home_navbar,
    Filter_product
  },
  data: () => ({ fetchedProducts: {}, filteredProducts: {} }

  ),

  mounted() {
    this.fetchProducts();
  },
  created() {
    EventBus.on('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
    EventBus.on('roleChanged', (newRole) => {
      this.role = newRole;
    });
  },
  beforeDestroy() {
    EventBus.off('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
    EventBus.off('roleChanged');
  },
  
  methods: {
    isLoggedIn() {
      const token = localStorage.getItem('token');
      return token !== null;
    },
    async fetchProducts() {
      const subcategory = this.$route.params.subcategory; // Get subcategory from route params
      const FETCH_PRODUCTS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/subcategories/${subcategory}/products/`;
      
      try {
        const response = await axios.get(FETCH_PRODUCTS_URL, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      
        this.fetchedProducts = response.data;
  
      } catch (error) {
        if (error.response.status === 404) {
          console.error('Subcategory not found');
        } else {
          console.error('Error fetching products:', error);
        }
      }
    },

    filter_product_list(data) {
        this.filteredProducts = data;
      },
      getLocalStorage(name) {
        const value = localStorage.getItem(name);
        return value ? JSON.parse(value) : null;
      },
      
      setLocalStorage(name, value) {
        localStorage.setItem(name, JSON.stringify(value));
      },
      
      
      async fetchCartDetails() {
        const cart = this.getLocalStorage('cart') || [];
        
        for (const item of cart) {
        
            const GET_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/fetch_image/${item.id}/`;
            const response = await fetch(GET_IMAGE_URL);
            
            if (!response.ok) {
              throw new Error('Failed to fetch image URL');
            }
            const imageData = await response.json();
            item.image = imageData;
        }
      
        this.cartItems = cart;
        this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
        this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
        EventBus.emit('cart-updated', this.cartQuantity);
      }, 
  
      async addToCart(product) {
        const quantity = 1; 
        const productId = product.id;
  
        if (this.isLoggedIn()) {
          try {
            const token = localStorage.getItem('token');
            const ADD_TO_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;
            const response = await fetch(ADD_TO_CART_URL, {
              method: 'POST',
              headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                product_id: productId,
                quantity: quantity
              })
            });
  
            const data = await response.json();
            const product_name = data.cart_products[0].product_name;
            Swal.fire({
              title: "Success!",
              text: `${product_name} has been added to your cart!`,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            }).then(() =>{
              EventBus.emit('cart-updated', data.total_quantity);
            })
          } catch (error) {
            console.error('Error adding to cart:', error);
          }
        } else {
          const cart = this.getLocalStorage('cart') || [];
          const existingProduct = cart.find(item => item.id === productId);
          if (existingProduct) {
            existingProduct.quantity += quantity;
          } else {
            cart.push({
              id: product.product_id,
              product_name: product.product.product_name,
              quantity: quantity,
              price: product.price,
              color: product.color,
              brand: product.product.brand__brand_name,
              image: product.product.image_url,
              product_id: productId
            });
          }
          this.setLocalStorage('cart', cart);
          this.fetchCartDetails();
          Swal.fire({
            title: "Success!",
            text: `${product.product.product_name} has been added to your cart!`,
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          }).then(() => {
            EventBus.emit('cart-updated', this.cartQuantity);
          })
        }
      },
  },
  watch: {
    $route(to, from) {
      if (to.params.subcategory !== from.params.subcategory) {
        this.fetchProducts();
      }
    }
  },
}