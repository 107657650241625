/* global $ */
import Admin_sidebar from '@/components/Admin/Admin_sidebar/AdminView.vue';
import Admin_navbar from '@/components/Admin/Admin_Navbar/AdminNavView.vue'
import 'datatables.net';
import 'datatables.net-bs4';

export default {
  name: 'OrdersView',
  components: {
    Admin_sidebar,
    Admin_navbar
  },
  data: () => ({
     orders: '',
    dataTable: null 
    }),

  updated() {
    // Initialize DataTable whenever videos data is updated
    this.$nextTick(() => {
      if ($.fn.DataTable.isDataTable('#orders_table')) {
        $('#orders_table').DataTable().destroy(); // Destroy previous instance
      }
      $('#orders_table').DataTable({
        pageLength: 10,
        lengthChange: false,
        searching: true,
        ordering: true,
        paging: true,
        info: true,
        autoWidth: false,
        columnDefs: [
          { orderable: false, targets: 5 } // Disable ordering on the 'Action' column
        ]
      });
    });
  },

  methods: {

  },
  mounted() {
    
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
  }
}