
<div class="container-fluid ">
  <Influencer_navbar v-model:search_input="search_query"/>
  <Products_navbar />
  <br>

  <div v-if="!search_query">
    <div v-if="influencer_videos.length === 0" class="text-center">
      <div class="hero">
          <div class="video-container  border-0">
          <div class="fade-overlay"></div>
              <video :src="require('@/assets/images/default_video.mp4')" autoplay controls class="h-100">
                  
              </video>
          </div>
          <div class="row">
              <div class="col-md-5">
                  <div class="hero-content text-start text-light">
                      <div class="d-flex align-content-center">
                          <img :src="require('@/assets/images/orange-icon-logo.png')" alt="Kyosk Logo" class="hero-logo ">
                          <h3 class="ms-2 mt-n1">Khiosk </h3>
                      </div>  
                      <p></p>
                    </div>
              </div> 
          </div>            
      </div>
  </div>
    <div v-else>
      <div v-if="influencer_videos.length > 0">
      <div class="hero">
          <div class="video-container border-0">
              <div class="fade-overlay"></div>
              <video ref="heroVideo" autoplay playsinline controls>
                  <source :src="influencer_videos[0].video_url" type="video/mp4" />
              </video>
              <div v-if="userInteracted" class="play-button-overlay" @click="enableAutoplay">
                  <i class="bx bx-play-circle"></i>
              </div>
          </div>
          <div class="row">
              <div class="col-md-5">
                  <div class="hero-content text-start text-light">
                      <div class="d-flex align-content-center">
                          <img :src="require('@/assets/images/orange-icon-logo.png')" alt="Kyosk Logo" class="hero-logo">
                          <h3 class="ms-2 mt-n1">Khiosk</h3>
                      </div>
                      <p>{{ influencer_videos[0].caption }}</p> <!-- Display the video caption -->
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="hero-cards hero-product">
                      <div class="bg-transparent">
                          <div class="scroller-container">
                              <div class="sub-scroller" ref="scroller">
                                  <input type="hidden" ref="video_id" :value="influencer_videos[0]?.id">
                                  <div class="logo-description-container">
                                      <div class="justify-content-end">
                                          <!-- Check if the video has associated products -->
                                          <div v-if="influencer_videos[0].products && influencer_videos[0].products.length">
                                              <router-link v-for="(productObj, index) in influencer_videos[0].products" :key="index" :to="{ name: 'product', params: { id: productObj.product_details.id } }" class="card h-100">
                                                  <img :src="productObj.product.image_url" class="rounded-12 p-1 h-130 mw-90 card-img-top" @error="(event) => event.target.src = require('@/assets/images/img-error.png')">
                                                  <div class="card-body text-centre">
                                                      <p class="card-text description text-white">{{ productObj.product.product_name }}</p>  
                                                      <p class="card-text description text-white">by {{ productObj.product.brand__brand_name}}</p>                                                                
                                                    
                                                      <!-- Check if product details exist, and display relevant details -->
                                                      <div v-if="productObj.product_details">
                                                          <h5 class="card-title text-warning fw-bold">R{{ productObj.product_details.price }}</h5>
                                                      </div>
                                                      <div v-else>
                                                          <p class="text-white">Details not available</p>
                                                      </div>
                                                  </div>
                                              </router-link>
                                          </div>
                                          <!-- If there are no products, display a message -->
                                          <div v-else>
                                              <p class="text-white">No products available for this video.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <br>   

<!-- Influencer Colection SECTION-->
    <h3 class="mt-4 fw-bold">Video Collection</h3>
    <div class="px-5 d-flow-root subvideo-content mb-5">
        <div class="scroller-container">
            <div class="scroller">
                <div class="card-container" :style="{ transform: `translateX(-${currentPosition * (100 / visibleCards)}%)` }">
                    <div v-for="(video, index) in influencer_videos" :key="index" class="card">
                        <div class="product_modal">
                            <div class="view-product-list">
                                <div ref="videoPlayer_{{index}}"  @click="openFullScreen(video.id, index)">
                                    <video preload="metadata" playsinline webkit-playsinline class="ideo-js vjs-default-skin rounded-12 img-fluid h-250 object-fit-cover w-100">
                                    <!-- Add source with type -->
                                        <source :src="video.video_url" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                    
                                        <div class="circle-images pb-85">
                                            <router-link v-for="(product, imgIndex) in video.products.filter(p => p.product_details)" :key="imgIndex" :to="{ name: 'product', params: { id: product.product_details.id } }">
                                            <img :src="product.product.image_url" class="circle-image"  @error="(event) => event.target.src = require('@/assets/images/img-error.png')">
                                            </router-link>
                                        </div>
                                    
                                </div>
                        </div>
                        </div>
                        <div class="text-start mt-2 ms-1">
                            <h6 class="fw-bold">{{ video.caption }}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Scroll Buttons -->
            <button class="scroll-btn left" @click="scrollCards('left')"><i class='bx bxs-chevron-left'></i></button>
            <button class="scroll-btn right" @click="scrollCards('right')"><i class='bx bxs-chevron-right'></i></button>
        </div>
    </div>



    <div v-if="isFullScreenMode" class="full-screen-container">
        <!-- Close Button -->
        <button class="close-btn" @click="exitFullScreen">
        <i class="bx bx-x"></i>
        </button>
    
        <div v-if="isFetching" class="spinner-container">
            <div class="spinner-border text-light" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="reel-container">
        <div v-for="(reel, index) in reels" :key="index" class="reel" :ref="'videoPlayer_' + index">
            <video :id="`video-${reel.id}`" :data-index="index" class="video-js vjs-default-skin"  playsinline autoplay preload="auto" @play="setCurrentPlaying(index)">
                <source :src="reel.video_url" type="video/mp4" />
            </video>
        <div v-if="userInteracted" class="play-button-overlay" @click="enableAutoplay">
        <i class="bx bx-play-circle"></i>
        </div>
        <div class="circle-images pb-85">
        <router-link v-for="(product, imgIndex) in reel.products" :key="imgIndex" :to="{ name: 'product', params: { id: product.product.id } }">
            <img :src="product.product.image_url" class="modal-circle-image" @error="(event) => event.target.src = require('@/assets/images/img-error.png')"/>
        </router-link>
        </div>
        </div>
        <div ref="scrollSentinel" class="scroll-sentinel"></div>
        
        </div>
    </div>


  </div> 

</div>

<div class="container home-product">
  <h1 class="text-center my-4 ms-4 text-dark fw-bold">Latest Offers This Month</h1>
   
  <div class="row">
    <div class="card card-large">
        <span class="card-text text-dark">New Collection</span>
    </div>
  </div>

  <div class="row">
    <div class="card card-small home-band">
          <span class="card-text text-white">Brands  </span>
    </div>
    <div class="card card-small home-influencer">
        
        <span class="card-text text-white">Influencer  </span>
    </div>
   
  </div>
</div>
</div>
<div v-else>
  <div class="container">
    <div class="row">
        <div class="col-md-3">
            <Filter_product
            @filter-products="filter_product_list"
            />
        </div>

        <div class="col-md-9">
            <div class="items-found">{{ filteredProducts.length }} items found</div>

            <!-- Dropdown to show combined results -->
            <div v-if="showDropdown" class="dropdown-menu">
                <div
                    v-for="result in filteredProducts"
                    :key="result.id"
                    @click="selectResult(result)"
                    class="dropdown-item"
                >
                <span v-if="result.type === 'product'">{{ result.product_name }}</span>
                <span v-else-if="result.type === 'brand'">Brand: {{ result.brand__brand_name }}</span>
                <span v-else-if="result.type === 'category'">Category: {{ result.category__category_name }}</span>
                <span v-else>Description: {{ result.description }}</span>
                </div>
            </div>
        
            <div class="product-grid">
                <router-link :to="{name: 'product_detail', params: {id: product.id, video_id: product.product.brand_video_id }}" class="product-card" v-for="product in filteredProducts" :key="product.id">
                    <input type="hidden" ref="image_id" :value="product.id">
                    <div class="product-image" :style="{ backgroundImage: `url(${product.product.image_url})` }">          
                    </div>
                    <div class="product-info text-start">
                        <p class="product-title text-black">{{ product.product.product_name }}</p>
                        <p class="product-brand">{{ product.product.brand__brand_name }}</p>
                        <div class="d-flex justify-content-between align-items-center">
                                <p class="product-price text-black">R{{ product.price }}</p>
                                <div class="d-flex justify-content-between">
                                  <i class="fa-sharp fa-regular fa-heart text-black fa-lg me-2" @click.stop="addWishlist(product)"></i>
                                  <i class="fa fa-cart-plus text-black fa-lg" aria-hidden="true" @click.stop="addToCart(product)"></i>
                                </div>
                              </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</div>
</div>


<footer class="footer">
  <div class="footer-content">
      <div class="footer-links">
          <div class="footer-column">
              <h3>Shop Now</h3>
              <ul>
                  <li><a href="#">Rich Mnisi</a></li> 
              </ul>
          </div>
          <div class="footer-column">
              <h3>Trends</h3>
              <ul>
                  <li><a href="#">South Africa</a></li> 
              </ul>
          </div>
          <div class="footer-column">
              <h3>Infuencer</h3>
              <ul>
                  <li><a href="#">X</a></li>
                  <li><a href="#">Instagram</a></li> 
              </ul>
          </div>
          <div class="footer-column">
              <h3>Custom Care</h3>
              <ul>
                  <li><a href="#">Contact Us</a></li>
                  <li><a href="#">Payment Method</a></li> 
              </ul>
          </div>
      </div>
      <div class="footer-bottom">
          <div class="footer-legal">
              <p>&copy; 2024 Khiosk. All rights reserved.</p>
              <div class="footer-legal-links">
                  <a href="#">Privacy Policy</a>
                  <a href="#">Terms of Use</a> 
              </div>
          </div>
      </div>
  </div>
</footer>
</div>