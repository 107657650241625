import $ from 'jquery'; 

export default {

    methods: {
      async logout() {
        const token = localStorage.getItem('token');
        const LOGOUT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/logout/`;

        const response = await $.ajax({
          url: LOGOUT_URL,
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response) {
          localStorage.removeItem('token'); // Corrected: This should be 'token', not 'localStorage.removeItem(token)'
          this.$router.push('/')
        } else {
          throw new Error('Logout failed');
        }
    },
    },
  };