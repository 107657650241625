<div class="home-navbar ">
  <Sidebar />
</div>  

<div class="app-content">  
  <Navbar />
    <div class="content">
      <div class="upload-search-row">
        <button type="button" class="action-btn" v-if="!impersonating" data-toggle="modal"
         data-target="#uploadModal" aria-label="Open"><span class="plus-sign">+</span>
          Add User</button>
          
      </div>
      <div class="card table-card">
      <div class="card-body">
        <table id="content_table" class="table align-item table-flush-item content-table">
          <thead class="thead-light">
            <tr>
              <th class="text-uppercase text-xxs fw-bold opacity-9">First Name</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Last Name</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Email</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Role</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Date Assigned</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
            </tr>
          </thead>

          <tbody>

            <tr v-for="user in users" :key="user.id">
              <td class="align-item">
                <span class="text-xxs font-weight-light mb-0">{{ user.first_name }}</span>
              </td>
              <td class="align-item">
                <span class="text-xxs font-weight-light mb-0">{{ user.last_name }}</span>
              </td>
              <td class="align-item">
                <span class="text-xxs font-weight-light mb-0">{{ user.email }}</span>
              </td>
              <td class="align-item">
                <span class="text-xxs font-weight-light mb-0">{{ user.role }}</span>
              </td>
              <td class="align-item">
                <span class="text-xxs font-weight-light mb-0">{{user.date_joined}}</span>
              </td>
              <td class="align-item d-flex justify-content-center">
                  <button class="btn-sm action-btn font-weight-light text-xxs" v-if="!impersonating" @click="editUser(user)">Edit</button>
  
                  <button class="btn-sm action-btn font-weight-light text-xxs" v-if="!impersonating" @click="deactivateUser(user)">{{ user.is_active ? 'Deactivate' : 'Activate' }}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div> 
</div>

<div class="modal" id="uploadModal">
  <div class="modal-content">
    <button type="button" class="close-btn" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3>Add User</h3>
    <div class="modal-body">
      <form @submit.prevent="submitForm"> <!-- Prevent default form submission -->
        <div class="form-group">
          <label for="first_name" class="text-label">First Name</label>
          <input v-model="form.first_name" type="text" class="form-control" id="first_name" required />
        </div>
        <div class="form-group">
          <label for="last_name" class="text-label">Last Name</label>
          <input v-model="form.last_name" type="text" class="form-control" id="last_name" required />
        </div>
        <div class="form-group">
          <label for="email" class="text-label">Email</label>
          <input v-model="form.email" type="email" class="form-control" id="email" required />
        </div>
        <div class="form-group">
          <label for="role" class="text-label">Role</label> 
          <select v-model="form.role" class="form-control" id="role" required>
            <option value="" disabled>Select Role</option>
            <option value="1">Admin</option>
            <option value="6">Sales Team</option>
            <option value="5">Inventory Management</option>
        </select>
        </div>
        <button type="submit" class="create-btn">Create</button>
      </form>
    </div>
  </div>
</div>


<div class="modal" id="editUserModal">
  <div class="modal-content">
    <button type="button" class="close-btn" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3>Edit User</h3>
    <div class="modal-body">
    <form @submit.prevent="updateUser">
      <div class="form-group">
        <label for="first_name" class="text-label">First Name</label>
        <input v-model="form.first_name" type="text" class="form-control" id="first_name" required />
      </div>
      <div class="form-group">
        <label for="last_name" class="text-label">Last Name</label>
        <input v-model="form.last_name" type="text" class="form-control" id="last_name" required />
      </div>
      <div class="form-group">
        <label for="email" class="text-label">Email</label>
        <input v-model="form.email" type="email" class="form-control" id="email" required />
      </div>
      <div class="form-group">
        <label for="role" class="text-label">Role:</label>
        <input type="text" v-model="form.role" class="form-control" id="role" readonly />
      </div>
      
      <button type="submit" class="create-btn">Save Changes</button>

    </form>
  </div>
</div>
</div>