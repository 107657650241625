<!-- <Home_navbar /> -->
<Home_navbar v-if="!LoggedIn" />
        <Influencer_navbar v-else />
        <div v-if="showLoginAlert" class="centered-popup alert-success">
          You need to log in to add products to the wishlist! 
          <a href="/login" class="alert-link"> LOGIN</a>
        </div>
        <div v-if="showSizelert" class="centered-popup alert-success">
          You need to select size before adding to cart! 
        </div>
        <div v-if="addedToWishlist" class="centered-popup alert-success">
          Successfully added to wishlist!
          <a href="/wishlist" class="alert-link"> WISHLIST</a>

        </div>

   
  <!-- Main Content -->
  <div class="  mt-1 product-view" v-if="product">
    <p class="text-start text-black ">
      <router-link to="/" class="text-muted">
        Home
      </router-link> / Brand / 
      <span class="fw-bold">{{ product.product.product_name }}</span>
    </p>
    <div class="row">
      <div class="col-md-5  "> 
        <img :src="product.product.image_url"  
          class="img-fluid h-400 object-fit-cover rounded-12" 
          @error="(event) => event.target.src = require('@/assets/images/img-error.png')"
        >
      </div>
      <div class="col-md-5 p-3">
        <div class="text-start"> 
          <h2 class="fw-bold mb-0 text-black">{{ product.product.product_name }}</h2>           
          <p class="product-view-brand">{{ product.product.brand__brand_name }}</p>
          <h3 class="fw-bold text-black">R {{ product.price }}</h3>
          <hr> 

          <div class="quantity-selector text-black">
            <h5 class="fw-bold">Quantity:</h5>
            <button class="btn btn-outline-secondary quantity-btn"  @click="decreaseQuantity"><i class="bx bx-minus"></i></button>
             <span class="quantity-number">{{ quantity }}</span>
            <button class="btn btn-outline-secondary quantity-btn"  @click="increaseQuantity"><i class="bx bx-plus"></i></button>
          </div>
          <br>
          <h5 class="colour-capitalize fw-bold text-black">Color: {{ product.color }}</h5>

          <h5 class="fw-bold text-black">Size:</h5>
          <div class="d-flex mb-3 text-black">
              <div class="size-option me-2" 
                v-for="size in sizes" :key="size" 
                :class="{ active: selectedSize === size.size }"
                @click="selectSizeProduct(size)"
              >                
                {{ size.size }}
              </div>
          </div> 

          <p class="text-muted"><i class='bx bxs-shopping-bag'></i>  <span class="fw-bold"> Only {{product.quantity}} left  </span> - don't miss out.</p>
          <!-- Buttons -->
          <div class="action-buttons justify-content-center align-content-center d-flex">
            <button class="add-to-cart  rounded-12" @click="addToCart(product.id)"> <i class="fa-solid fa-cart-shopping wishlist-icon"></i> Add to Cart
            </button>
            <button class="add-to-wishlist rounded-12" @click="addToWishlist(product.id)"> <i class="fa-sharp fa-regular fa-heart wishlist-icon"></i>Add to Wishlist</button>
          </div>
          <hr>
          <h5 class="fw-bold text-black">description</h5>
          <p class="product-view-brand"> {{ product.product.description }}</p>
        </div>
      </div>

    </div>
  </div>

  <footer class="footer">
    <div class="footer-content">
        <div class="footer-links">
            <div class="footer-column">
                <h3>Shop Now</h3>
                <ul>
                    <li><a href="#">Rich Mnisi</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Trends</h3>
                <ul>
                    <li><a href="#">South Africa</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Influencer</h3>
                <ul>
                    <li><a href="#">X</a></li>
                    <li><a href="#">Instagram</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Custom Care</h3>
                <ul>
                    <li><a href="#">Contact Us</a></li>
                    <li><a href="#">Payment Method</a></li> 
                </ul>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="footer-legal">
                <p>&copy; 2024 Khiosk. All rights reserved.</p>
                <div class="footer-legal-links">
                    <a href="#">Privacy Policy</a>
                    <a href="#">Terms of Use</a> 
                </div>
            </div>
        </div>
    </div>
</footer>