
<div class="home-navbar ">
  <Sidebar />
</div>

<div class="app-content mt-3">
  <Navbar />
  <br>
  <div class="d-flex align-items-end">
      <button type="button" class="action-btn" v-if="!impersonating" data-bs-toggle="modal" data-bs-target="#uploadModalImage">
          Upload Images
        </button>
  </div>
  <br>

  <div class="container-fluid ">
    <div class="video-container ">
      <video  id="video_player" autoplay muted loop  controls class="rounded-12">
        <source id="video_source" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <!-- Image Gallery Under Video -->
      <br>
      <!-- Image Gallery Under Video -->
      <div v-if="image_files.length > 0" class="image-gallery d-flex align-items-center justify-content-center">
        <button v-if="image_files.length > 4" class="nav-btn prev-btn" @click="scrollLeft">&lt;</button>
        <div class="image-containers" ref="imageContainer">
          <div class="row flex-nowrap overflow-auto"  >
            <div v-for="image in image_files" :key="image.id" class="col-md-3 col-sm-4">
              <!-- Adjust to col-3 to show 4 items in a row -->
              <div class="card">
                <button class="delete-btn-icon" v-if="!impersonating" @click="deleteImage(image.id)">
                  <i class="fa-solid fa-trash"></i>
                </button>
                <input type="hidden" name="video_image_id" id="video_image_id" value="">
                <img :src="image.product.image_url" class="card-img-top rounded-12 p-1 mw-90 h-130 img-fluid card-img-top" @error="(event) => event.target.src = require('@/assets/images/img-error.png')" @click="openUpdateModal(image)">
                <div class="card-body text-start" >
                  <p class="card-text description">{{ image.product.product_name }}</p>
                  <div class="d-flex align-items-start flex-column bd-highlight mb-3">
                    <p class="card-text description font-italic">By {{ image.product.brand__brand_name }}</p>
                    <p class="card-text text-warning text-xxs">R{{ image.price }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button v-if="image_files.length > 4"  class="nav-btn next-btn" @click="scrollRight">&gt;</button>
      </div>
    </div>
  </div>
</div>
 

<div class="modal fade" id="uploadModalImage" tabindex="-1" aria-labelledby="uploadModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="uploadModalLabel">Image Upload</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <!-- Add the form tag here -->
        <form id="uploadForm" >
          <div id="imageUploadContainer">
            <div class="upload-group">
              <div class="form-section">
                <div class="row">
                  <!-- Image Upload Section -->
                  <div class="col-md-6">
                    <div class="image-container">
                      <div class="upload-box-image">
                        <label for="imageUpload" class="upload-label">
                          <div class="upload-icon" id="uploadIcon">📤</div>
                          <p id="uploadText">Upload Image</p>
                          <img id="imagePreview" controls class="image-preview">
                        </label>
                      </div>
                    </div>
                    <input type="file" id="image_url" accept="image/*" class="file-inputs" required>
                  </div>

                  <!-- Form Fields Section -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="productName" class="text-label">Product Name:</label>
                      <input type="text" id="product_name" name="productName" class="form-control form-control-sm" required>
                    </div>

                    <div class="form-group mt-2">
                      <label for="description" class="text-label">Description:</label>
                      <textarea id="description" name="description" class="form-control form-control-sm" required></textarea>
                    </div>

                    <div class="form-group mt-2">
                      <label for="no_of_items" class="text-label">No of Stock:</label>
                      <input type="text" id="no_of_items" v-model="current_image.no_of_items" class="form-control form-control-sm" required>
                    </div>

                    <div class="form-group mt-2">
                      <label for="department" class="text-label">Department:</label>
                      <select id="department" name="department" class="form-control form-control-sm" v-model="selectedDepartment" @change="updateCategories" required>
                        <option v-for="department in departments" :key="department.id" :value="department.id">
                          {{ department.department_name }}
                        </option>
                      </select>
                      <input v-if="selectedDepartment === 'Other'" type="text" id="department_input" name="department-input" class="form-control form-control-sm mt-2" v-model="newDepartment" @keyup.enter="addDepartment" placeholder="Type a new department">
                    </div>

                    <div class="form-group mt-2">
                      <label for="categories" class="text-label">Category:</label>
                      <select id="categories" name="categories" class="form-control form-control-sm" v-model="selectedCategory" @change="updateSubcategories" required>
                        <option v-for="category in categories" :key="category.id" :value="category.id">
                          {{ category.category_name }}
                        </option>
                      </select>
                      <input v-if="selectedCategory === 'Other'" type="text" id="category-input" name="category-input" class="form-control form-control-sm mt-2" v-model="newCategory" @keyup.enter="addCategory" placeholder="Type a new category">
                    </div>

                    <div class="form-group mt-2">
                      <label for="sub_category" class="text-label">Sub-Category:</label>
                      <select id="sub_category" name="subcategories" class="form-control form-control-sm" v-model="selectedSubcategories" required>
                        <option v-for="subcategory in subcategories" :key="subcategory.id" :value="subcategory.id">
                          {{subcategory.subcategory_name}}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Size and Color Options Section -->
                  <div class="form-group mt-3">
                    <div class="table-container" style="padding: 15px; overflow-x: auto;">
                      <table class="table table-bordered" style="min-width: 600px;">
                        <thead>
                          <tr>
                            <th>Size</th>
                            <th>Size Quantity</th>
                            <th>Color</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody id="size_table">
                          <tr class="size-color-row">
                            <td class="size-input">
                              <input type="text" class="form-control form-control-sm" id="size" name="size_input" required>
                            </td>
                            <td class="size-quantity">
                              <input type="number" class="form-control form-control-sm" id="size_quantity" name="size_quantity" required>
                            </td>
                            <td class="color-cell">
                              <input type="text" class="form-control form-control-sm" id="color" name="colorSmall" required>
                            </td>
                            <td class="color-price">
                              <input type="text" class="form-control form-control-sm price" id="price" name="color_price" required>
                            </td>
                
                          </tr>
                        </tbody>
                      </table>
                      <button type="button" id="addMore" class="action-btn add-size">add size</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" id="upload_image" class="action-btn" @click="uploadImage">Upload</button>
        <button type="button" id="addMoreImages" class="action-btn">Add More</button>
      </div>
    </div>
  </div>
</div>




<!-- Update Modal -->
<div class="modal fade" id="updateModalImage" tabindex="-1" aria-labelledby="updateModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateModalLabel">Update Image</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-section">
          <input type="hidden" id="update_video_image_id" v-model="current_image.id" >
          <div class="row">
            <div class="col-md-6">
              <div class="image-container">
                <!-- Display current image or placeholder -->
                <img :src="current_image.image_url" alt= "Current Image" class="img-preview">
              </div>
              <div class="form-group mt-2">
                <label for="new_image" class="text-label">New Image:</label>
                <input type="file" id="new_image" ref="newImage" class="form-control form-control-sm "  @change="previewImage">
              </div>
            </div>
            
            <div class="col-md-6">
              <div class="form-group">
                <label for="productName" class="text-label">Product Name:</label>
                <input type="text" id="product_name" v-model="current_image.product_name" name="productName" class="form-control form-control-sm" required>
              </div>
              <div class="form-group mt-2">
                <label for="update_description" class="text-label">Description:</label>
                <input type="text" id="update_description" v-model="current_image.description" class="form-control form-control-sm" required>
              </div>
              <div class="form-group mt-2">
                <label for="update_code" class="text-label">SKU:</label>
                <input type="text" id="update_description" v-model="current_image.code" class="form-control form-control-sm" disabled>
              </div>
              <div class="form-group mt-2">
                <label for="update_stock" class="text-label">No of Stock:</label>
                <input type="text" id="update_no_of_items" v-model="current_image.no_of_items" class="form-control form-control-sm">
              </div>
              
              <div class="form-group mt-2">
                <label for="department" class="text-label">Department:</label>
                <select id="department" name="department" class="form-control form-control-sm" v-model="selectedDepartment"
                @change="updateCategories">
                  <option v-for="department in departments" :key="department.id" :value="department.id">
                    {{ department.department_name }}
                  </option>
                </select>
                <input
                v-if="selectedDepartment === 'Other'" type="text" id="department_input" name="department-input" class="form-control form-control-sm mt-2"
                v-model="newDepartment" @keyup.enter="addDepartment" placeholder="Type a new department">
              </div>
              <div class="form-group mt-2">
                <label for="categories" class="text-label">Category:</label>
                <select id="categories" name="categories" class="form-control form-control-sm" v-model="current_image.category_id"  @change="updateSubcategories">
                  <option v-for="category in categories" :key="category.id" :value="category.id">
                    {{ category.category_name }}
                  </option>
                </select>
                <input
                v-if="selectedCategory === 'Other'" type="text" id="category-input" name="category-input" class="form-control form-control-sm mt-2"
                v-model="newCategory" @keyup.enter="addCategory" placeholder="Type a new category">
              </div>

              <div class="form-group mt-2">
                <label for="subcategories" class="text-label">Sub-Category:</label>
                <select id="sub_category" name="subcategories" class="form-control form-control-sm" v-model="selectedSubcategories">
                  <option v-for="subcategory in subcategories" :key="subcategory.id" :value="subcategory.id">
                    {{subcategory.subcategory_name}}
                  </option>
                </select>
              </div>
            </div>
            
             <!-- Size and Color Options Section -->
             <div class="form-group mt-3">
              <div class="table-container" style="padding: 15px; overflow-x: auto;">
                <table class="table table-bordered" style="min-width: 600px;">
                  <thead>
                    <tr>
                      <th>Size</th>
                      <th>Size Quantity</th>
                      <th>Color</th>
                      <th>Price</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody id="update_size_table">
                    <!-- XSmall Size Row -->
                    <tr class="size-color-row">
                      <td class="update-size-input">
                        <input type="text" class="form-control form-control-sm" v-model="current_image.size" name="size-input">
                      </td>
                      <td class="update-size-quantity">
                        <input type="number" class="form-control form-control-sm" v-model="current_image.quantity" name="size_quantity">
                      </td>
                      <td class="update-color-cell">
                        <input type="text" class="form-control form-control-sm" v-model="current_image.color" name="colorSmall">
                      </td>

                      <td class="update-color-price">
                        <input type="text" class="form-control form-control-sm" v-model="current_image.price" name="color_price">
                      </td>
                      <td class="d-flex align-items-center justify-content-center">
                        <button type="button" class="btn btn-sm btn-success  update-add-color-btn ">+</button>
                        <button type="button" class="btn btn-sm btn-danger update-remove-color-btn">-</button>
                      </td>
                    </tr>
                   
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="action-btn" @click="updateImage">Update</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

  