/* global $ */
import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';

export default {
    name: 'TaggedView',
    components: {
      Sidebar,
      Navbar,
    },

    data: () => ({ influencers: {} }),

      methods:{
   
        async fetchInfluencers() {
          const token = localStorage.getItem('token');
          const impersonate = localStorage.getItem('impersonate');
          const brand_id = localStorage.getItem('brand_id');
          let FETCH_INFLUENCERS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/brands/influencers/`;
        
          try {
            if (impersonate === 'true') {
              FETCH_INFLUENCERS_URL += `?brand_id=${brand_id}&impersonate=true`;
            }
        
            const response = await fetch(FETCH_INFLUENCERS_URL, {
              headers: {
                'Authorization': `Token ${token}`
              }
            });
        
            const data = await response.json();
            this.influencers = data;
            this.initializeDataTable();
          } catch (error) {
            console.error('Error fetching influencers:', error);
          }
        },

      initializeDataTable() {
        this.$nextTick(() => {
          if ($.fn.DataTable.isDataTable('#content_table')) {
            $('#content_table').DataTable().destroy();
          }
          this.dataTable = $('#content_table').DataTable({
            pageLength: 10,
            lengthChange: false,
            searching: true,
            ordering: true,
            paging: true,
            info: true,
            autoWidth: false,
            
          });
        });
      },
    },
    
    mounted() {
      this.fetchInfluencers();
    },
}