<div class="home-navbar">
  <Sidebar />
</div>

<div class="app-content mt-1">
  <Navbar />
  <div class="content">
    <div v-if="videos.length === 0" class="text-center mt-5">
      <img :src="require('@/assets/images/no-data-image.jpg')" alt="No Data Available" class="no-data-image">
      <p class="no-data-message text-secondary">No data available.</p>
    </div>
    <div v-else class="row align-items">
      <div class="col-sm-6 col-md-6 mb-4" v-for="video in videos" :key="video.id">
        <div class="card h-100 bg-light shadow-lg border border-light">
          <div class="card-body row g-0 d-flex align-items-stretch">
            <div class="row g-0">
              <!-- Video cover image on the left -->
              <router-link :to="{ name: 'manage_content', params: { video_id: video.id } }">
                <div class="col-md-4">
                  <router-link :to="{ name: 'manage_content', params: { video_id: video.id } }" class="card-title text-secondary text-decoration-underline d-flex justify-content-start">
                    <img :src="video.cover_image || 'placeholder.png'" class="rounded-12 p-1 bg-white h-150 img-fluid card-img-top" alt="Video Thumbnail">
                  </router-link>
                </div>
              </router-link>
              <!-- Video content on the right -->
              <div class="col-md-7 p-3">
                <div class="card-title text-secondary text-decoration-underline d-flex justify-content-start">
                  <h5>{{ video.sub_description }}</h5>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <span>
                    <i class="bi bi-clock icon-color"></i>
                    {{ video.duration ? video.duration + ' seconds' : 'N/A' }}
                  </span>
                  <span>
                    <i class="bi bi-calendar icon-color"></i>
                    {{ video.date_uploaded ? video.date_uploaded : 'N/A' }}
                  </span>
                  <span>
                    <i class="bi bi-eye icon-color"></i>
                    {{ video.views ? video.views + ' views' : '0 views' }}
                  </span>
                </div>
                <div class="card-text d-flex justify-content-between mb-2">
                  <p class="mb-1">
                    <i class="bi bi-archive icon-color"></i>
                    {{ video.number_of_images }} item{{ video.number_of_images > 1 ? 's' : '' }}
                  </p>
                  <ul class="list-unstyled">
                    <li v-for="item in video.image_descriptions" :key="item">{{ item }}</li>
                  </ul>
                </div>
                <p class="mt-2 d-flex justify-content-start"><i class="bi bi-cash icon-color me-1"></i> R {{ video.total_price }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>