<div class="sidebar">
  <div class="logo-details mx-3 mt-4 mb-3">
    
    <router-link to="/" class="d-inline-block"> 
      <img :src="require('@/assets/images/logo-text.svg')" class="img-fluid ms-1" width="150" alt="profileImg">
    </router-link>
  </div>
  <div class="close-btn text-black close-sidebar">
    <i class='bx bx-x'></i>
  </div>

  <ul class="nav-links">
    <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapse_utilities"
        aria-expanded="true" aria-controls="collapse_utilities">
        <span class="heading">Profile</span>
    </a> 
  
    <li>
      <div class="iocn-link">
        <router-link to="/Influencer_profile">
          <i class="bx bx-loader-circle"></i>
          <span class="link-name">Profile details</span>
        </router-link>
        
      </div>

    </li>

    <li>
      <div class="iocn-link">
        <a href="#">
          <router-link to="/addresses">
            <i class="bx bx-loader-circle"></i>
            <span class="link-name">Delivery Address</span>
          </router-link>
        </a>
      </div>
    </li>
    <li>
      <div class="iocn-link">
        <a href="#">
            <i class="bx bx-loader-circle"></i>
            <span class="link-name">Security</span>
        </a>
        
      </div>
    </li>

    <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapse_utilities"
        aria-expanded="true" aria-controls="collapse_utilities">
        <span class="heading">Orders</span>
    </a> 
    <li>
      <div class="iocn-link">
        <a href="#">
            <i class="bx bx-loader-circle"></i>
            <span class="link-name">Orders</span>
        </a>
      </div>
    </li>
    <li>
      <div class="iocn-link">
        <a href="#">
            <i class="bx bx-loader-circle"></i>
            <span class="link-name">Return</span>
        </a>
      </div>
    </li>
    <li>
      <div class="iocn-link">
        <router-link to="/wishlist">
          <i class="bx bx-loader-circle"></i>
          <span class="link-name">Wishlist</span>
        </router-link>
      </div>
    </li>
   

    <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapse_utilities"
        aria-expanded="true" aria-controls="collapse_utilities">
        <span class="heading">Payment</span>
    </a> 
  
    <li>
      <div class="iocn-link">
        <a href="#">
          <i class="bx bx-loader-circle"></i>
          <span class="link-name">Payment Details</span>
        </a>
      </div>
    </li>
    <li>
      <div class="iocn-link">
        <a href="#">
            <i class="bx bx-loader-circle"></i>
            <span class="link-name">Banking Details</span>
        </a>
      </div>
    </li>
    <li>
      <div class="iocn-link">
        <a href="#">
          <i class="bx bx-loader-circle"></i>
          <span class="link-name">Refunds</span>
        </a>
      </div>
    </li>
    <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapse_utilities"
    aria-expanded="true" aria-controls="collapse_utilities" href="#" @click="logout">
    <span class="heading">Logout</span>
    <i class="bx bx-log-in ms-2"></i>
</a> 
    
  </ul> 
</div>
 