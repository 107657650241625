import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';

export default {
    components: {
        Home_navbar,
        Influencer_navbar
      },
      data: () => ({ 
        items: [],
        UserLoggedIn: false,
      }),
  
      methods: {

        checkLoginStatus() {
          this.UserLoggedIn = !!localStorage.getItem('token');
        },

        async fetchInfluencers() {
          const FETCH_INFLUENCER_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/get_influencer_api/`;
          try {
            const response = await fetch(FETCH_INFLUENCER_URL, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              },
            });
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            this.items = data.filter(influencer => influencer.videos.length > 0);
          } catch (error) {
            console.error('Error fetching influencers:', error);
          }
        },
        playVideo(event) {
          const videoElement = event.target;
      
          // Clear any existing timeout to avoid overlapping calls
          if (videoElement.playTimeout) {
            clearTimeout(videoElement.playTimeout);
          }
      
          // Set a timeout to delay the play action
          videoElement.playTimeout = setTimeout(() => {
            videoElement.play().catch((error) => {
              console.error("Autoplay error:", error);
            });
          }, 500); // Delay by 500ms (adjust as needed)
        },
      
        pauseVideo(event) {
          const videoElement = event.target;
      
          // Clear the play timeout if the user stops hovering
          if (videoElement.playTimeout) {
            clearTimeout(videoElement.playTimeout);
          }
      
          // Pause the video
          videoElement.pause();
          videoElement.currentTime = 0; 
        },      
    
      },
    
      mounted() {
        this.fetchInfluencers();
        this.checkLoginStatus();
       
      },
      
      
    }
  