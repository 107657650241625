/* global $ */

import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';

export default {
    name: 'TaggedView',
    components: {
      Sidebar,
      Navbar,
    },
    methods:{

        initializeDataTable() {
          this.$nextTick(() => {
            if ($.fn.DataTable.isDataTable('#content_table')) {
              $('#content_table').DataTable().destroy();
            }
            this.dataTable = $('#content_table').DataTable({
              pageLength: 10,
              lengthChange: false,
              searching: true,
              ordering: true,
              paging: true,
              info: true,
              autoWidth: false,
              
            });
          });
        },
      },
      mounted() {
        this.initializeDataTable();
      },
}