<Home_navbar />

<div class="container" v-if="Object.keys(fetchedProducts).length > 0">
    <div class="row">
    <div class="col-md-3">

        <Filter_product
        @filter-products="filter_product_list"
        />
    </div>
    <div class="col-md-9">
      <div class="items-found">{{ Object.keys(fetchedProducts).length }} items found</div>
      
      <div class="product-grid">
        <div class="product-card" v-for="product in fetchedProducts" :key="product.id">
          <router-link 
            :to="{ name: 'product_detail', params: { id: product.id, video_id: product.product.brand_video_id }}" 
            class="product-link"
          >
            <!-- Product Image and Info -->
            <div class="product-image" :style="{ backgroundImage: `url(${product.product.image_url})` }">          
            </div>
          </router-link>
            <div class="product-info text-start">
                <p class="product-title text-black">{{ product.product.product_name }}</p>
                <p class="product-brand"> {{ product.product.brand__brand_name }}</p>
                
                <div class="d-flex justify-content-between align-items-center">
                  <p class="product-price text-black">R{{ product.price }}</p>
                  <div class="d-flex justify-content-between">
                    <i class="fa-sharp fa-regular fa-heart text-black fa-lg me-2" @click.stop="addWishlist(product)"></i>
                    <i class="fa fa-cart-plus text-black fa-lg" aria-hidden="true" @click.prevent="addToCart(product)"></i>
                  </div>
                </div>
              </div>
        </div>      
      </div>
    </div>
  </div>
</div>

<div v-else>
    <h1 class="font-weight-bold mb-5 mt-5 text-muted ">No Results Found</h1>
</div>


<footer class="footer">
    <div class="footer-content">
        <div class="footer-links">
            <div class="footer-column">
                <h3>Shop Now</h3>
                <ul>
                    <li><a href="#">Rich Mnisi</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Trends</h3>
                <ul>
                    <li><a href="#">South Africa</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Influencer</h3>
                <ul>
                    <li><a href="#">X</a></li>
                    <li><a href="#">Instagram</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Custom Care</h3>
                <ul>
                    <li><a href="#">Contact Us</a></li>
                    <li><a href="#">Payment Method</a></li> 
                </ul>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="footer-legal">
                <p>&copy; 2024 Khiosk. All rights reserved.</p>
                <div class="footer-legal-links">
                    <a href="#">Privacy Policy</a>
                    <a href="#">Terms of Use</a> 
                </div>
            </div>
        </div>
    </div>
</footer>