/* global $ */
import EventBus from '@/eventBus.js';

export default {
  name: 'HomeNavbarView',

  props: {
    search_input: {
      required: false,
      type: String
    },
    currentRole: {
      type: String,
      default: 'customer', // Default role
    },
  },

  data() {
    return {
      selectedRole: 'customer',
      departments: [],
      sidebarOpen: false,
      subSidebarOpen: [], 
      selectedDepartmentIndex: null,
      cartQuantity: 0,
      cartTotal: 0, 
      isCartOpen: false,
      cartItems: JSON.parse(localStorage.getItem('cart')) || [],
      isRightSidebarOpen: false,



    };
  },

  

  created() {
    this.fetchCategories();
    this.fetchCartDetails();
    
    EventBus.on('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
    this.selectRole('customer');

    EventBus.on('roleChanged', (newRole) => {
      this.role = newRole;
    });

    EventBus.on('toggle-right-sidebar', () => {
      this.fetchCartDetails();
      this.isRightSidebarOpen = true;
    });

    EventBus.on('update-cart', () => {
      this.fetchCartDetails();
      this.isRightSidebarOpen = true;

    });

  },

  beforeDestroy() {
    // Cleanup event listener
    EventBus.off('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
    EventBus.off('roleChanged');
  },

  methods: {
    handleClickOutside(event) {
      const dropdown = this.$refs.cartDropdown;
      const iconContainer = this.$refs.iconContainer;

      // Check if the click is outside the dropdown and icon container
      if (dropdown && !dropdown.contains(event.target) && !iconContainer.contains(event.target)) {
        this.closeCart();
      }
    },
  
    async fetchCartDetails() {
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
    
      for (const item of cart) {
      
          const GET_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/fetch_image/${item.id}/`;
          const response = await fetch(GET_IMAGE_URL);
          
          if (!response.ok) {
            throw new Error('Failed to fetch image URL');
          }
    
          const imageData = await response.json();
          item.image = imageData;
      }
    
      this.cartItems = cart;
      this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
      this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
      EventBus.emit('cart-updated', this.cartQuantity);
    },    

    deleteFromCart(id, product_id) {
      // Get the cart from local storage
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
      
      const updatedCart = cart.filter(item => item.id !== id || item.product_id !== product_id);
    
      localStorage.setItem('cart', JSON.stringify(updatedCart));
    
      // Update the cartItems and fetch updated cart details
      this.cartItems = updatedCart;

      this.fetchCartDetails();
    
      // Emit the cart updated event
      EventBus.emit('cart-updated', updatedCart.length);
    },
    increaseQuantity(id, product_id) {
      // Find the cart item by its id and product_id
      const item = this.cartItems.find(item => item.id === id && item.product_id === product_id);
      if (item) {
        item.quantity++;
        this.updateCartInLocalStorage(id, product_id);
      }
    },
    
    decreaseQuantity(id, product_id) {
      const item = this.cartItems.find(item => item.id === id && item.product_id === product_id);
      if (item && item.quantity > 1) {
        item.quantity--;
        this.updateCartInLocalStorage(id, product_id); 
      }
    },
    updateCartInLocalStorage(id, product_id) {
      // Retrieve the cart from local storage
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
    
      // Find and update the specific item by id and product_id
      const existingItem = cart.find(item => item.id === id && item.product_id === product_id);
      if (existingItem) {
        const updatedItem = this.cartItems.find(item => item.id === id && item.product_id === product_id);
        if (updatedItem) {
          existingItem.quantity = updatedItem.quantity;
        }
      }
    
      // Save the updated cart back to local storage
      localStorage.setItem('cart', JSON.stringify(cart));
      this.fetchCartDetails();
    },    
    
    goToLandingPage() {
      if (this.$route.path === '/') {
        location.reload();
      } else {
        this.$router.push('/');
      }
    },
    selectRole(role) {
      EventBus.emit('roleChanged', role);
      this.$emit('role-selected', role)
    },
    async fetchCategories() {
      const FETCH_CATEGORIES_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_categories/`;

      try {
        const response = await $.ajax({
          url: FETCH_CATEGORIES_URL,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        this.departments = response;
        // Initialize subSidebarOpen with false values for each department
        this.subSidebarOpen = Array(this.departments.length).fill(false);
      } catch (error) {
        console.error(error);
      }
    },

    update_search(event) {
      this.$emit('update:search_input', event.target.value);
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },

     toggleRightSidebar() {
      this.fetchCartDetails();
      this.isRightSidebarOpen = !this.isRightSidebarOpen;
    },
    toggleSubSidebar(departmentIndex) {
      this.subSidebarOpen[departmentIndex] = !this.subSidebarOpen[departmentIndex];
    },

    proceedToCheckout() {
      const token = localStorage.getItem('token');
      if (!token) {
        const currentUrl = '/shipping_address'; // Set the intended URL
        localStorage.setItem('intendedUrl', currentUrl);
        this.$router.push('/login');
      } else {
        this.$router.push('/shipping_address');
      }
    }
  }
};
