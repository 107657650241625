
<div class="container">

    <div class="items-found">{{ filteredProducts.length }} items found</div>

    <!-- Dropdown to show combined results -->
    <div v-if="showDropdown" class="dropdown-menu">
      <div
        v-for="result in filteredProducts"
        :key="result.id"
        @click="selectResult(result)"
        class="dropdown-item"
      >
      <span v-if="result.type === 'product'">{{ result.product_name }}</span>
      <span v-else-if="result.type === 'brand'">Brand: {{ result.brand__brand_name }}</span>
      <span v-else-if="result.type === 'category'">Category: {{ result.category__category_name }}</span>
      <span v-else>Description: {{ result.description }}</span>
    </div>
  </div>

    <!-- Display combined results -->
    <div class="product-grid">
      <div class="product-card" v-for="product in filteredProducts" :key="product.id" @click.prevent="selectResult(product)">
        <div class="product-image" :style="{ backgroundImage: `url(${product.product.image_url})` }">          
        </div>
        <div class="product-info">
          <h3 class="product-title text-black">{{ product.product.product_name }}</h3>
          <p class="product-brand">By {{ product.product.brand__brand_name }}</p>
          <p class="product-price text-black">
            R{{ product.price }}
          </p>
        <div  class=" d-flex justify-content-center align-items-center">
          <button class="action-btn">ADD TO CART</button>
          <i class="fa-sharp fa-regular fa-heart"></i>
        </div>
        </div>
      </div>
    </div>
  </div>

