<div class="home-navbar ">
    
  <div class="top-banner">
    <div class="row"> 
      <div class="col-md-3 col-12 d-flex justify-content-start align-items-center"> 
        <div class="col top-banner-sm">
          <a href="#" 
                     class="btn btn-link" 
                     :class="{ active: currentRole === 'customer' }"
                     @click.prevent="selectRole('customer')">Customer</a>
                  <a href="#" 
                     class="btn btn-link" 
                     :class="{ active: currentRole === 'brand' }"
                     @click.prevent="selectRole('brand')">Brand</a>
                  <a href="#" 
                     class="btn btn-link" 
                     :class="{ active: currentRole === 'influencer' }"
                     @click.prevent="selectRole('influencer')">Influencer</a>
        </div>
      </div>
      <div class="col-md-6 d-flex justify-content-center align-items-center">
          Free delivery on orders over R1,000.00
      </div>

      
    </div>
  </div>
 
  <nav class="first-navbar">
    <i class='bx bx-menu fs-4 text-dark ms-3 close-sidebar' @click="toggleSidebar"></i> 

    <img :src="require('@/assets/images/new_logo_organge_black.png')" alt="Kyosk Logo" class=" logo" @click="goToLandingPage" >
    <div class="search-bar search-bar-lg">
        <input type="text" placeholder="Search for items and brands" id="search-input" :value="search_input" @input="update_search">
        <div class="search-dropdown" id="search-dropdown">
            <a href="#">Popular search 1</a>
            <a href="#">Popular search 2</a>
            <a href="#">Popular search 3</a>
            <a href="#">Popular search 4</a>
            <a href="#">Popular search 5</a>
        </div>
    </div>
    <div class="nav-icons">
      <div class="icon-container" @click="toggleRightSidebar" ref="iconContainer">
          <a href="#">
            <i class='bx bx-shopping-bag' id="cart-icon"></i>
            <span class="cart-quantity" id="cart-quantity">{{ cartQuantity }}</span>
          </a>
        </div>
        <div class="icon-container" data-dropdown>
            <a href="#"><i class='bx bx-user'></i></a>
            <div class="dropdown-content"> 
                <router-link to="/register" class="">
                  <span class="text-dark" >Sign Up</span>
                </router-link>
       
                <router-link to="/login" class=" ">
                  <span class="text-dark">Login</span>
                </router-link>
            </div>
        </div>       
    </div>
  </nav>
  
  <div class="search-bar mt-3 search-bar-sm">
    <input type="text" placeholder="Search for items and brands" id="search-input" :value="search_input" @input="update_search">
    <div class="search-dropdown" id="search-dropdown"> 
    </div>
</div>
 <br>
  <nav class="second-navbar" :class="{'sidebar-visible': isSidebarVisible}">
    <div class="nav-item" data-dropdown>
        <a href="#">NEW IN</a>
        <div class="dropdown-content">
            <a href="#">Women</a>
            <a href="#">Men</a>
            <a href="#">Kids</a>
        </div>
    </div>
    <div v-for="(department, departmentIndex) in departments" :key="departmentIndex" class="nav-item" data-dropdown>
      
      <a href="#" class="department-name">{{ department.department_name }}</a>
      <div class="dropdown-content align-items justify-content-center">
        <div v-for="(category, categoryIndex) in department.categories" :key="categoryIndex" class="subcategory-section">
          <a href="#" class="category-name">{{ category.category_name }}</a>
          <div v-if="category.subcategories.length" class="subcategory-container">
            <router-link 
              v-for="(subcategoryName, subcategoryIndex) in category.subcategories" 
              :key="subcategoryIndex" 
              :to="{ name: 'subcategory-products', params: { subcategory: subcategoryName.subcategory_name } }">
              {{ subcategoryName.subcategory_name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-item" data-dropdown>
        <a href="/view_brands">BRANDS</a>
    </div>
  </nav>
</div>



<!-- Main Sidebar -->
<div :class="{'open': sidebarOpen}" id="sidebar">
  
  
  <div class="sidebar-links d-flex justify-content-between align-items-center">
    <a href="#" class="text-black ms-3" 
       :class="{ active: selectedRole === 'brand' }" 
       @click.prevent="selectRole('brand')">Brand</a>
      <a href="#" class="text-black me-2" 
         :class="{ active: selectedRole === 'influencer' }" 
         @click.prevent="selectRole('influencer')">Influencer</a>
      <a href="#" class="text-black ms-1" @click="toggleSidebar">
        <i class='bx bx-x'></i>
      </a>
  </div>
  <!-- Departments Loop -->
  <div v-for="(department, departmentIndex) in departments" :key="departmentIndex" class="nav-item department">
    <a href="#" class="sidebar-item department-name-sidebar text-start" @click.prevent="toggleSubSidebar(departmentIndex)">
      {{ department.department_name }}
    </a>
    
    <!-- Sub-Sidebar for Categories -->
    <div :class="{'open': subSidebarOpen[departmentIndex]}" id="subSidebar" v-if="department.categories.length">
      <a href="#" class="back-btn start-0" @click="toggleSubSidebar(departmentIndex)"><i class='bx bx-arrow-back'></i> {{department.department_name }}</a>
      
      <div v-for="(category, categoryIndex) in department.categories" :key="categoryIndex">
        <a href="#" 
        class="sidebar-item dropdown-toggle category-name text-start d-flex justify-content-between" 
        data-bs-toggle="collapse" 
           :data-bs-target="'#categoryDropdown' + departmentIndex + categoryIndex" 
           aria-expanded="false" 
           :aria-controls="'categoryDropdown' + departmentIndex + categoryIndex">
          {{ category.category_name }}
        </a>
  
        <!-- Only show subcategories when toggle button is clicked -->
        <div :id="'categoryDropdown' + departmentIndex + categoryIndex" class="collapse">
          <div v-if="category.subcategories.length" class="subcategory-container text-black text-start">
            <router-link 
              v-for="(subcategoryName, subcategoryIndex) in category.subcategories" 
              :key="subcategoryIndex" 
              :to="{ name: 'subcategory-products', params: { subcategory: subcategoryName.subcategory_name } }">
              {{ subcategoryName.subcategory_name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="nav-item text-start">
    <a href="/view_brands">BRANDS</a>

</div>
</div>


<!-- Sidebar Container -->
<div id="right-sidebar" :class="{'open': cartQuantity > 0 && isRightSidebarOpen}">
  <!-- Close Button -->
  <div class="view-cart-header d-flex justify-content-between align-items-center fixed-top">
    <h4 class="text-start fw-bold text-black ml-3">Cart</h4>
    <div class="close-btn text-black" @click="isRightSidebarOpen = false">
      <i class='bx bx-x'></i>
    </div>
  </div>
  
  <!-- Sidebar Content -->
  <div class="sidebar-content">
    <div v-for="item in cartItems" :key="item.id" class="cart-item">
      <img :src="item.image" alt="Product image" class="cart-item-image" />
      <div class="cart-item-details d-flex text-black">
        <h4 class="product-title fw-bold text-black mb-0">{{ item.product_name }}</h4>
        
        <p class="product-brand mb-1"> {{ item.brand }}</p>
        <p class="mb-0">size: {{ item.size }}</p>
        <p class="mb-2">color: {{ item.color }}</p>
        <p class="fw-bold">Price: R {{ (item.price * item.quantity) }}</p>
     
        <div class="d-flex align-items-center">
          <span class="me-2">Qty:</span>
          <button v-if="item.quantity === 1" class="btn btn-outline-secondary btn-sm text-black" @click="deleteFromCart(item.id, item.product_id)">
            <i class="fa-solid fa-trash"></i>
          </button>
          <button v-else class="btn btn-outline-secondary btn-sm text-black" @click="decreaseQuantity(item.id,item.product_id)">-</button>
          <span class="quantity mx-2">{{ item.quantity }}</span>
          <button class="btn btn-outline-secondary btn-sm text-black" @click="increaseQuantity(item.id,item.product_id)">+</button>
      </div>

        
      </div>
    </div>
  </div>

  <div class="view-cart-btn">
    <a href="#" class="add-to-cart text-white"@click="proceedToCheckout">Checkout <span class="mx-3">-</span> R{{ cartTotal }}</a>
  </div>
</div>




 