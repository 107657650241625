import $ from 'jquery'; 

export default {

  data() {
        return {
          impersonating: localStorage.getItem('impersonate') === 'true',
          logoUrl: require('@/assets/images/icon.jpg') };
      },

    methods: {
      goToUpdateBrand() {
        this.$router.push( '/update_brand');
      },

      async logout() {
        const token = localStorage.getItem('token');
        const LOGOUT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/logout/`;

        const response = await $.ajax({
          url: LOGOUT_URL,
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response) {
          localStorage.removeItem('token'); // Corrected: This should be 'token', not 'localStorage.removeItem(token)'
          this.$router.push('/')
        } else {
          throw new Error('Logout failed');
        }
    },
    },
  };
   


const navItems = document.querySelectorAll('.nav-item');

// Add click event listener to each nav-item
navItems.forEach(item => {
  item.addEventListener('click', function() {
    // Remove 'active' class from all nav-items
    navItems.forEach(nav => nav.classList.remove('active'));
    
    // Add 'active' class to the clicked nav-item
    this.classList.add('active');
  });
});

