
<div class="sidebar">
  <div class="logo-details mx-3 mt-4 mb-3">
    <router-link to="/" class="d-inline-block"> <!-- Change the route as needed -->
      <img :src="require('@/assets/images/logo-text.svg')" class="img-fluid ms-1" width="150" alt="profileImg">
    </router-link>

  </div>
   
      <ul class="nav-links">
        <li >
          <router-link to="/Admin_dashboard" exact-active-class="active">
            <a class="nav-link text-dark " href="#">
              <i class='bx bxs-home fs-6'></i> 
              Home
            </a>
          </router-link>
        </li>
        <li >
          <router-link to="/all_brands" exact-active-class="active">
            <a class="nav-link text-dark " href="#">
              <i class="bx bxs-user fs-6"></i> Brands
            </a>
          </router-link>
        </li>
        <li >
          <router-link to="/all_influencers" exact-active-class="active">
            <a class="nav-link text-dark " href="#">
              <i class="bx bxs-user fs-6"></i> Influencers
            </a>
          </router-link>
        </li>
        <li >
          <router-link to="/view_oders" exact-active-class="active">
            <a class="nav-link text-dark " href="#">
              <i class="bx bxs-package fs-6"></i> Orders
            </a>
          </router-link>
        </li>
        <li> 
          <router-link   to="/add_user" exact-active-class="active">
            <a class="nav-link text-dark text-sm " href="#">
              <i class='bx bxs-group fs-6'></i> 
              User Management
            </a>
          </router-link> 
        </li>
        <li> 
          <router-link   to="/" exact-active-class="active">
            <a class="nav-link text-dark " href="#">
              <i class='bx bx-wallet'></i> 
                Transaction Portal
            </a>
          </router-link> 
        </li>
        <li> 
          <router-link   to="/analytics" exact-active-class="active">
            <a class="nav-link text-dark " href="#">
              <i class='bx bx-line-chart fs-6'></i> 
              Analytics
            </a>
          </router-link> 
        </li> 

        <li>
          <div exact-active-class="active">
            <a class="nav-link text-dark" href="#" @click="logout">
              <i class="bx bx-log-out fs-6"></i>
              Logout
              </a>
          </div>
          
        </li>
      </ul>
 
      
  </div>
  