import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Swal from 'sweetalert2';
import videojs from 'video.js';

export default {
  props: {
        influencerId: {
            type: String,
            required: true
        }
    },
  components: {
    Home_navbar,
  },
  data: () => ({

      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        bio: '',
        profileImage: ''
      },
      videos: [],
      showEditProfileModal: false,
      profileImage: '',
      hoveredVideo: null,
      visibleCards: 6,
      currentPosition: 0,
      showDetails: [],
      influencerId: null,
      FETCH_VIDEOS_URL:'',
      reels: [],
      fullScreenReels: [],
      originalReels: [],
      page: 1,
      limit: 5,
      isFetching: false,
      isFullScreenMode: false,
      allDataLoaded: false,
      players: {},
      currentPlayingIndex: null,
      fullScreenVideoIndex: null,
      debounceTimer: null,
      UserLoggedIn: false, // Add a property to track login status
      currentVideo:null,
      pipVideoIndex: null,
      showSizes: false,
      selectedProduct: null,
      selectedSize: null,
      sizeProductId: null,
      currentPipProducts: [],
      isInPiPMode: false,

  }),
  created() {
    // Define influencerId here
    this.influencerId = this.$route.params.influencerId;
  },
  methods: {
    goBack() {
        this.$router.go(-1);
      },
    initializeShowDetails() {
      this.showDetails = new Array(this.videos.length).fill(false);
    },
    toggleDetails(index) {
      this.showDetails[index] = !this.showDetails[index];
    },
    toggleOverlay(index, isHovering) {
      const overlay = document.querySelectorAll('.overlay')[index];
      if (!this.showDetails[index] && isHovering) {
        overlay.classList.add('bg-dark');
      } else {
        overlay.classList.remove('bg-dark');
      }
    },
   
    scrollCards(direction) {
      const maxScroll = this.brand_videos.length - this.visibleCards;
      if (direction === 'right' && this.currentPosition < maxScroll) {
        this.currentPosition++;
      } else if (direction === 'left' && this.currentPosition > 0) {
        this.currentPosition--;
      }
    },
    async openEditProfileModal() {
      await this.fetchUserData();
      this.showEditProfileModal = true;
    },

    async fetchUserData() {
        const FETCH_USER_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/public_user_profile/${this.influencerId}/`;
        try {
          const response = await fetch(FETCH_USER_URL, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
          });
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();
        localStorage.setItem('user_id', data.user_id);

        if (data.status === "success") {
          this.form.first_name = data.first_name;
          this.form.last_name = data.last_name;
          this.form.email = data.email;
          this.form.phone_number = data.phone_number;
          this.form.bio = data.bio || '';  // Ensure the bio is set correctly
          this.profileImage = data.image || ''; 
        } else {
          Swal.fire({
            title: 'Error',
            text: data.error || 'Failed to fetch user data.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    

    async fetchVideos() {
        const FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/public_influencer_video_content/${this.influencerId}/`;
      try {
        const response = await fetch(FETCH_VIDEOS_URL, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch videos');
        }

        const data = await response.json();
        this.videos = data;
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },

  handleClickOutside(event) {
    const dropdown = this.$refs.dropdownContainer;
    if (dropdown && !dropdown.contains(event.target)) {
      this.showProductDropdown = false;
    }
  },
  
    triggerFileInput() {
      this.$refs.fileInput.click(); // Trigger file input click
    },

    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = document.getElementById('modalProfileImage');
          if (img) {
            img.src = e.target.result;
          }
        };
        reader.readAsDataURL(file);
        this.form.profileImage = file;
      }
    },
    

    saveProfile() {
      // Handle saving the profile information here
      this.showEditProfileModal = false;
    },
    closeModal() {
      this.showEditProfileModal = false;
    },

    playVideo(event) {
      const videoElement = event.target;
  
      // Clear any existing timeout to avoid overlapping calls
      if (videoElement.playTimeout) {
        clearTimeout(videoElement.playTimeout);
      }
  
      // Set a timeout to delay the play action
      videoElement.playTimeout = setTimeout(() => {
        videoElement.play().catch((error) => {
          console.error("Autoplay error:", error);
        });
      }, 1000); // Delay by 500ms (adjust as needed)
    },
  
    pauseVideo(event) {
      const videoElement = event.target;
  
      // Clear the play timeout if the user stops hovering
      if (videoElement.playTimeout) {
        clearTimeout(videoElement.playTimeout);
      }
  
      // Pause the video
      videoElement.pause();
      videoElement.currentTime = 0; 
    },

    async fetchReelData() {

      if (this.isFetching || this.allDataLoaded) return;
    
      try {
    
        this.isFetching = true;
    
        this.FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/public_influencer_video_content/${this.influencerId}/`;
    
        const response = await fetch(`${this.FETCH_VIDEOS_URL}?page=${this.page}&limit=${this.limit}`);
    
        if (!response.ok) {
    
          throw new Error(`HTTP error! status: ${response.status}`);
    
        }
    
        const data = await response.json();
    
        if (data.length === 0) {
    
          this.allDataLoaded = true;
    
        } else {
    
          this.reels.push(...data);
    
          this.page++;
    
          // Initialize Video.js and Observers after new reels are rendered
    
          this.$nextTick(() => {
    
            this.initializeVideoJS();
    
            this.initializeVideoObserver();
    
          });
    
        }
    
      } catch (error) {
    
        console.error("Error fetching reel data:", error);
    
      } finally {
    
        this.isFetching = false;
    
      }
    
    },
    
    initializeVideoJS() {
      this.reels.forEach((_, index) => {
        const videoEl = Array.isArray(this.$refs['videoPlayer_' + index]) ? this.$refs['videoPlayer_' + index][0] : this.$refs['videoPlayer_' + index];
    
        if (videoEl instanceof HTMLElement && !this.players[index]) {
    
          const videoElement = videoEl.querySelector('video');
    
          videoElement.setAttribute('playsinline', '');
    
          const player = videojs(videoElement, {
            autoplay: true,
            muted: true,
            controls: false,
            preload: "auto",
            loop: true,
            html5: {
              vhs: {
                overrideNative: false, // Use native playback where possible
              },
              nativeVideoTracks: true,
              nativeAudioTracks: true,
              nativeTextTracks: true,
            },
    
          });
    
          this.players[index] = player;
    
          // Remove play button overlay once video starts playing
          videoElement.addEventListener('play', () => {
            const playButtonOverlay = videoEl.querySelector('.play-button-overlay');
            if (playButtonOverlay) {
              playButtonOverlay.style.display = 'none';
            }
          });
    
          // Ensure play button overlay reappears if video is paused
          videoElement.addEventListener('pause', () => {
    
            if (!this.userInteracted) {
              const playButtonOverlay = videoEl.querySelector('.play-button-overlay');
              if (playButtonOverlay) {
                playButtonOverlay.style.display = 'block';
    
              }
    
            }
    
          });
    
          // Ensure overlay is clickable by adding a click event listener
          const playButtonOverlay = videoEl.querySelector('.play-button-overlay');
          if (playButtonOverlay) {
            playButtonOverlay.addEventListener('click', (event) => {
              event.stopPropagation(); // Prevent click from propagating to the video element
              this.enableAutoplay();
                playButtonOverlay.style.display = 'none';
            });
    
          }
    
        }
    
      });
    
    },
   
    initializeInfiniteScroll() {
      this.$nextTick(() => {
        const sentinel = this.$refs.scrollSentinel;
        if (sentinel) {
          const observer = new IntersectionObserver(
            (entries) => {
              if (entries[0].isIntersecting && !this.isFetching) {
                this.fetchReelData();
              }
            },
            {
              root: null,
              rootMargin: "100px",
              threshold: 0,
            }
          );
          observer.observe(sentinel);
        }
      });
    
    },
    
    initializeVideoObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.75,
      };
    
      if (!('IntersectionObserver' in window)) {
        console.warn('IntersectionObserver is not supported in this browser. Videos may not automatically play/pause.');
        return;
      }
    
      const observer = new IntersectionObserver((entries) => {

        entries.forEach((entry) => {
          const videoIndex = parseInt(entry.target.dataset.index, 10);
          if (videoIndex !== -1 && this.players[videoIndex]) {
            if (entry.isIntersecting) {
              if (!this.isFullScreenMode || this.fullScreenVideoIndex === videoIndex) {

                this.setCurrentPlaying(videoIndex);
                const playPromise = this.players[videoIndex].play();
    
                if (playPromise !== undefined) {
                  playPromise.catch((error) => {
                    console.error('Autoplay prevented:', error);
                  });
                }
              }
            } else {
              this.players[videoIndex].pause();
            }
          }
        });
      }, options);
    
      this.reels.forEach((_, index) => {
        const videoEl = Array.isArray(this.$refs['videoPlayer_' + index]) 
          ? this.$refs['videoPlayer_' + index][0] 
          : this.$refs['videoPlayer_' + index];
    
        if (videoEl) {

          const videoElement = videoEl.querySelector('video');
          videoElement.dataset.index = index;
          observer.observe(videoElement);
        }
      });
    },

    openFullScreen(video, index) {
      console.log('Opening fullscreen video:', video, index);
      if (this.currentPlayingIndex !== null && this.players[this.currentPlayingIndex]) {
        this.players[this.currentPlayingIndex].pause();
      }
    
      if (!this.originalReels.length) {
        this.originalReels = [...this.reels];
      }
    
      if (!this.originalReels || !this.originalReels[index]) {
        console.error("Invalid index or reels not loaded");
        return;
      }
    
      this.fullScreenReels = [...this.originalReels];
      this.isFullScreenMode = true;
      this.fullScreenVideoIndex = index;
      this.currentPlayingIndex = index;
    
      window.addEventListener("wheel", this.handleScrollInFullScreen);
    
      this.$nextTick(() => {
        this.playVideoAtIndex(index);
      });
    },
    
    handleScrollInFullScreen(event) {
    if (!this.isFullScreenMode) return;

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    // Debounce: Execute the navigation logic after a delay
    this.debounceTimer = setTimeout(() => {
      const direction = event.deltaY > 0 ? 1 : -1;
      this.navigateFullScreen(direction);
    }, 200); // Adjust the delay if needed
    },
  
    navigateFullScreen(direction) {
      if (!this.isFullScreenMode || !this.fullScreenReels.length) return;
    
      let nextIndex = this.fullScreenVideoIndex + direction;
    
      if (nextIndex < 0) {
        nextIndex = 0;
      } else if (nextIndex >= this.fullScreenReels.length) {
        nextIndex = this.fullScreenReels.length - 1;
      }
    
      if (nextIndex === this.fullScreenVideoIndex) {
        console.error("No more videos in this direction.");
        return;
      }
    
      if (this.currentPlayingIndex !== null && this.players[this.currentPlayingIndex]) {
        this.players[this.currentPlayingIndex].pause();
      }
    
      this.fullScreenVideoIndex = nextIndex;
      this.currentPlayingIndex = nextIndex;
    
      this.$nextTick(() => {
        this.playVideoAtIndex(nextIndex);
      });
    },
    
    playVideoAtIndex(index) {
      this.reels.forEach((_, i) => {
        const videoEl = Array.isArray(this.$refs[`videoPlayer_${i}`])
          ? this.$refs[`videoPlayer_${i}`][0]
          : this.$refs[`videoPlayer_${i}`];
    
        if (videoEl && i !== index) {
          const videoElement = videoEl.querySelector("video");
          if (videoElement) {
            videoElement.pause();
            videoElement.currentTime = 0; // Reset the video
          }
        }
      });
    
      // Play the selected video
      const videoEl = Array.isArray(this.$refs[`videoPlayer_${index}`])
        ? this.$refs[`videoPlayer_${index}`][0]
        : this.$refs[`videoPlayer_${index}`];
    
      if (videoEl) {
        const videoElement = videoEl.querySelector("video");
        if (videoElement) {
          videoElement.muted = true;
          videoElement.setAttribute("playsinline", "true");
          videoElement.setAttribute("autoplay", "true");
          videoElement.setAttribute("preload", "auto");
          videoElement.scrollIntoView({ behavior: "smooth", block: "center" });
    
          const playPromise = videoElement.play();
          if (playPromise !== undefined) {
            playPromise.catch((error) => {
              console.error("Autoplay prevented:", error);
              videoElement.controls = true; // Enable controls as fallback
            });
          }
        }
      }
    },

    exitFullScreen() {
      this.isFullScreenMode = false;
      this.fullScreenReels = [];
      this.fullScreenVideoIndex = 0;
      this.currentPlayingIndex = null;

      window.removeEventListener("wheel", this.handleScrollInFullScreen);
    },

   
    setCurrentPlaying(index) {
      if (this.currentPlayingIndex === index) return;
      // Pause the current playing video if it exists
      if (this.currentPlayingIndex !== null && this.players[this.currentPlayingIndex]) {
        this.players[this.currentPlayingIndex].pause();
      }
    
      // Update the current playing index
      this.currentPlayingIndex = index;

      const videoEl = Array.isArray(this.$refs['videoPlayer_' + index])
        ? this.$refs['videoPlayer_' + index][0]
        : this.$refs['videoPlayer_' + index];

      if (videoEl) {
        const videoElement = videoEl.querySelector('video');
        if (videoElement) {
          // Initialize the player if not already initialized
          if (!this.players[index]) {
            this.players[index] = videojs(videoElement, {
              autoplay: true,
              muted: true, // Ensure videos are muted for autoplay
              controls: false,
              preload: "auto",
              loop: true,
              html5: {
                vhs: {
                  overrideNative: false, // Use native playback where possible
                },
                nativeVideoTracks: true,
                nativeAudioTracks: true,
                nativeTextTracks: true,
              },
            });
          }
          videoElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },


    async enterPictureInPicture(reel, index) {

      const videoElement = document.getElementById(`video-${reel.id}`);
      
      if (!videoElement) {
        console.error('Video element not found!');
        return;
      }

      try {

        if (document.pictureInPictureElement) {
          await document.exitPictureInPicture();
        }
        // Enter PiP mode
        await videoElement.requestPictureInPicture();

        // Save the current PiP index
        this.isInPiPMode = true;
        this.pipVideoIndex = index;
        // Add event listener for leavepictureinpicture event
        this.currentPipProducts = reel.products.filter((p) => p.product_details); // Store product details

        videoElement.addEventListener('leavepictureinpicture', this.handleLeavePictureInPicture);
      } catch (error) {
        console.error('Failed to enter Picture-in-Picture mode:', error);
      }
    },

    handleLeavePictureInPicture() {
      this.isInPiPMode = false;
      // Check if there was a PiP video index saved
      if (this.pipVideoIndex !== null) {
    
        // Retrieve the reel and open it in fullscreen
        const reel = this.reels[this.pipVideoIndex];
        if (reel) {
          this.$router.go(-1);
          this.$nextTick(() => {
            this.openFullScreen(reel, this.pipVideoIndex);
          });
        }
    
      }
    
    },

    enableAutoplay() {
      
      this.userInteracted = true;
    
      if (this.players[this.currentPlayingIndex]) {
    
        this.players[this.currentPlayingIndex].play();
    
        // Remove play button overlay
        const videoEl = Array.isArray(this.$refs['videoPlayer_' + this.currentPlayingIndex]) ? this.$refs['videoPlayer_' + this.currentPlayingIndex][0] : this.$refs['videoPlayer_' + this.currentPlayingIndex];
    
        if (videoEl) {
          const playButtonOverlay = videoEl.querySelector('.play-button-overlay');
          if (playButtonOverlay) {
            if (this.userInteracted) {
              playButtonOverlay.style.display = 'none';
            }
    
          }
    
        }

      }
    
    },
  },

  mounted() {
    this.fetchUserData();
    this.fetchVideos();
    this.handleClickOutside();    
  
    // jQuery event handlers
    
    this.initializeShowDetails();
    this.fetchReelData().then(() => {
      this.initializeVideoJS();
      this.initializeVideoObserver();
    });
    this.initializeInfiniteScroll();
    document.addEventListener('exitPictureInPicture', this.handleLeavePictureInPicture);

  },
  beforeDestroy() {
    this.handleClickOutside();
    Object.values(this.players).forEach((player) => {
    
      if (player && typeof player.dispose === 'function') {
  
        player.dispose();
  
      }
  
    });
  
    this.players = {};
  
    this.originalReels = [];
  
    this.fullScreenReels = [];
    document.removeEventListener('exitPictureInPicture', this.handleLeavePictureInPicture);
  
  }
};
