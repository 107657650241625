<div class="home-navbar"></div>
    <Sidebar />
  
  <div class="app-content">
    <Navbar />
    <div class="content">
      <div class="upload-search-row">
      </div>
      <div class="card table-card">
        <div class="card-body"> 
          <table id="content_table" class="table align-item table-flush-item content-table">
            <thead class="thead-light">
              <tr>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Customer</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Customer Name</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Code</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Order Date</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Total</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Status</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>

              </tr>
            </thead>
  
            <tbody>
                <tr>
                    <td class="text-start">
                        <img>
                        </td>
                    <td class="text-start">
                    <span class="text-xxs font-weight-light mb-0"> </span>
                    </td>
                    <td class="text-start">
                        <span class="text-xxs font-weight-light mb-0"></span>
                    </td>
                    <td class="text-start">
                        <span class="text-xxs font-weight-light mb-0"></span>
                    </td>
                  <td class="text-start">
                    <img>
                  </td>
                  <td class="text-start">
                    <span class="text-xxs font-weight-light mb-0"></span>
                    
                  </td>
                  
                  <td class="text-start">
                    <button 
                      class="btn-sm action-btn font-weight-light text-xxs">
                      View
                    </button>
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>