import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue'
import Swal from 'sweetalert2';

export default {
  components: {
    Influencer_sidebar,
    Influencer_navbar,
    Filter_product
  },
  data() {
    return {
      form: { first_name: '', last_name: '', email: '', phone_number: '', bio: '', profileImage:'' },
      videos: [],
      showEditProfileModal: false,
      profileImage: '',
      hoveredVideo: null,
      visibleCards: 6,
      currentPosition: 0,
      showDetails: [],
      search_query: "",
      allProducts: [] 
    };
  },
  computed: {
    filteredProducts() {
      if (!this.search_query) return this.allProducts;
    
      const query = this.search_query.toLowerCase();
      return this.allProducts.filter(product => {
        const { product_name = '', brand__brand_name = '', description = '', category__category_name = '' } = product.product || {};
    
        return [product_name, brand__brand_name, description, category__category_name]
          .some(field => field.toLowerCase().includes(query));
      });
    },
  },
  methods: {
    update_search_query(query) {
      this.search_query = query;
    },
    filter_product_list(data) {
      this.allProducts = data;
      this.filteredProducts = data;

      },
      fetchSearchProducts() {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`;
    
        fetch(FETCH_PRODUCT_URL)
          .then(response => response.json())
          .then(data => {
            this.allProducts = data;
          })
          .catch(error => console.error('Error fetching products:', error));
      },
    
      filterProducts() {
        const query = this.search_query.toLowerCase();
        this.filteredProducts = [...this.allProducts];
    
        if (query) {
          const search = new RegExp(query, 'i');
          this.filteredProducts = this.filteredProducts.filter((product) => {
            const match = (
              search.test(product.product.product_name) ||
              search.test(product.product.brand__brand_name) ||
              search.test(product.product.description) ||
              search.test(product.product.category__category_name)
            );
            return match;
          });
        }
      },
    initializeShowDetails() {
      this.showDetails = new Array(this.videos.length).fill(false);
    },
    toggleDetails(index) {
      this.showDetails[index] = !this.showDetails[index];
    },
    toggleOverlay(index, isHovering) {
      const overlay = document.querySelectorAll('.overlay')[index];
      if (!this.showDetails[index] && isHovering) {
        overlay.classList.add('bg-dark');
      } else {
        overlay.classList.remove('bg-dark');
      }
    },
   
    scrollCards(direction) {
      const maxScroll = this.brand_videos.length - this.visibleCards;
      if (direction === 'right' && this.currentPosition < maxScroll) {
        this.currentPosition++;
      } else if (direction === 'left' && this.currentPosition > 0) {
        this.currentPosition--;
      }
    },
    async updateUser() {
      const formData = new FormData();
      
      // Append form fields to FormData
      formData.append('first_name', this.form.first_name);
      formData.append('last_name', this.form.last_name);
      formData.append('email', this.form.email);
      formData.append('phone_number', this.form.phone_number || "");
      formData.append('bio', this.form.bio);
      formData.append('user_id', localStorage.getItem('user_id'));
    
      // Check if a profile image was selected, and append it to FormData
      if (this.form.profileImage) {
        formData.append('profile_image', this.form.profileImage);  // Ensure the key matches backend expectations
      }
    
      try {
        const token = localStorage.getItem('token');
        const UPDATE_PROFILE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/update_profile/`;
    
        const response = await fetch(UPDATE_PROFILE_URL, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
            // Do not set 'Content-Type' manually for FormData; the browser will set it automatically
          },
          body: formData,  // Send FormData instead of JSON
        });
    
        if (!response.ok) {
          throw new Error('Failed to update user data');
        }
    
        const data = await response.json();
    
        if (data.status === "success") {
          Swal.fire({
            icon: 'success',
            text: data.message,
            title: 'Success',
            confirmButtonText: 'OK'
          }).then(() => {
            window.location.reload();
          });
        } else {
          throw new Error('Failed to update user data');
        }
      } catch (error) {
        console.error('Error updating user data:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to update user data.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },
    

    async openEditProfileModal() {
      await this.fetchUserData();
      this.showEditProfileModal = true;
    },

    async fetchUserData() {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/user_info/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();
        localStorage.setItem('user_id', data.user_id);

        if (data.status === "success") {
          this.form.first_name = data.first_name;
          this.form.last_name = data.last_name;
          this.form.email = data.email;
          this.form.phone_number = data.phone_number;
          this.form.bio = data.bio || ''; 
          this.profileImage = data.image; 
        } else {
          Swal.fire({
            title: 'Error',
            text: data.error || 'Failed to fetch user data.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    

    async fetchVideos() {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/view_content/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch videos');
        }

        const data = await response.json();
        this.videos = data;
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },

  handleClickOutside(event) {
    const dropdown = this.$refs.dropdownContainer;
    if (dropdown && !dropdown.contains(event.target)) {
      this.showProductDropdown = false;
    }
  },
  
    triggerFileInput() {
      this.$refs.fileInput.click(); // Trigger file input click
    },

    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = document.getElementById('modalProfileImage');
          if (img) {
            img.src = e.target.result;
          }
        };
        reader.readAsDataURL(file);
        this.form.profileImage = file;
      }
    },
    

    saveProfile() {
      // Handle saving the profile information here
      this.showEditProfileModal = false;
    },
    closeModal() {
      this.showEditProfileModal = false;
    },
  },
  

  mounted() {
    this.fetchUserData();
    this.fetchVideos();
    this.handleClickOutside();
    this.fetchSearchProducts();  
  
    // jQuery event handlers
    
    this.initializeShowDetails();
  },

  watch: {
    search_query() {
      this.filterProducts();

    }
  },
  beforeDestroy() {
    this.handleClickOutside();
  }
};
